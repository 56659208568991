<template>
  <div class="min-h-screen">
    <div class="p-6 md:p-12">
      <div class="max-w-4xl mx-auto">
        <!-- Header -->
        <h1 class="text-3xl md:text-4xl font-display font-bold text-slate-100 mb-8">
          Terms and Conditions
        </h1>

        <!-- § 1. General provisions -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            § 1. General provisions
          </h2>
          <div class="space-y-4 text-slate-300">
            <ol class="list-decimal list-outside ml-6 space-y-4">
              <li>These Terms and Conditions (hereinafter: the "Terms and Conditions" or "Regulations") set out the rules and conditions for the use of the application (hereinafter: the "App" or "Application") and the services provided by the Service Provider.</li>
              <li>The application is a tool for creating fairy tales and stories, which -- based on the use of generative artificial intelligence -- considers parameters necessary for generating the text, either independently defined or selected by the Client within the App.</li>
              <li>The Terms and Conditions are the rules and regulations referred to in Article 8 of the Act of 18 July 2002 on the provision of services by electronic means (hereinafter: the "Electronic Services Act").</li>
              <li>The Service Provider is <span class="text-slate-200">KIDS TELL TALES spółka z ograniczoną odpowiedzialnością</span> (limited liability co.) based in Kraków, address: ul. Stelmachów 46b/2, 31-341 Kraków, Poland, entered into entrepreneurs' register administered by the District Court for Kraków- Śródmieście based in Kraków, XI Commercial Division of National Court Register under KRS no. 0001141843, holding Tax ID no. 9452302626, REGON: 54032510300000, with a share capital of 10 050 PLN (hereinafter: "Service Provider").</li>
              <li>Contact with the Service Provider is possible using:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>e-mail at the following address: <a href="mailto:support@kidstelltales.com" class="text-blue-400 hover:text-blue-300">support@kidstelltales.com</a>;</li>
                  <li>postal service -- at the address: Stelmachów 46b/2, 31-341 Kraków, Poland;</li>
                  <li>phone -- at the number: +48 519 481 489</li>
                </ol>
              </li>
              <li>In accordance with the provisions of Regulation (EU) 2022/2065 of the European Parliament and of the Council of 19 October 2022 on the single market for digital services and amending Directive 2000/31/EC (Digital Services Act) (hereinafter: "DSA"), the Service Provider has appointed a contact point for direct communication with the authorities of the EU Member States, the European Commission, the Digital Services Council and Application Users on matters covered by the DSA regulation. The contact point can be found at: <a href="mailto:support@kidstelltales.com" class="text-blue-400 hover:text-blue-300">support@kidstelltales.com</a>. Communication may be conducted in English.</li>
              <li>Information about services available in the App, in particular their descriptions, technical and functional parameters, and prices, constitute an invitation to conclude a contract for the delivery of services within the meaning of art. 71 of the Act of 23 April 1964 Civil Code (hereinafter: "Civil Code").</li>
              <li>The Client and the User are obliged to read the Terms and Conditions before using the App.</li>
            </ol>
          </div>
        </section>

        <!-- § 2. Definitions -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            § 2. Definitions
          </h2>
          <div class="space-y-4 text-slate-300">
            <p>Capitalized words used in the Regulations have the following meaning:</p>
            <ol class="list-decimal list-outside ml-6 space-y-4">
              <li><strong class="text-slate-200">Account</strong> - a panel created in the IT system of the Application, enabling the User to use its functionalities, including the Account Service;</li>
              <li><strong class="text-slate-200">Account Service</strong> -- a digital service within the meaning of the provisions of the Act on consumer rights, consisting in the creation and maintenance of an Account by the Service Provider for the User;</li>
              <li><strong class="text-slate-200">Act on the provision of electronic services</strong> - a term defined in § 1 section 2 of the Regulations;</li>
              <li><strong class="text-slate-200">Agreement</strong> -- Agreement for the provision of the Account Service and the Agreement for the provision of the Application Use Service;</li>
              <li><strong class="text-slate-200">Agreement for the provision of the Account Service</strong> -- an agreement for the provision of a digital service within the meaning of the Act on consumer rights, under which the Service Provider undertakes to provide the Account Service to the User for an indefinite period of time, and the User undertakes to provide the Service Provider with his data, including personal data;</li>
              <li><strong class="text-slate-200">Agreement for the provision of the Application Use Service</strong> -- an agreement for the provision of a digital service within the meaning of the Act on consumer rights, under which the Service Provider undertakes to provide the Client with the Application Use Service for a fee; the condition for concluding the Agreement for the provision of the Service for the use of the Application is the prior conclusion of the Agreement for the provision of the Account Service;</li>
              <li><strong class="text-slate-200">Application Use Service</strong> -- a digital service within the meaning of the Act on consumer rights, consisting in enabling the Client to use the functionality of the Application consisting in: enabling the creation of fairy tales and stories based on parameters defined within the Application Use Service, using artificial intelligence (AI). The process of creating texts is described within the Application Use Service</li>
              <li><strong class="text-slate-200">Civil Code</strong> - a term defined in § 1 section 6 of the Terms and Conditions;</li>
              <li><strong class="text-slate-200">Client</strong> -- a service recipient using the Application, being a Consumer;</li>
              <li><strong class="text-slate-200">Consumer</strong> - a natural person performing a legal transaction with the Service Provider not directly related to its business or professional activity;</li>
              <li><strong class="text-slate-200">Consumer Rights Act</strong> - the Act of 30 May 2014 on consumer rights;</li>
              <li><strong class="text-slate-200">Digital Service</strong> -- Account Service and Application Use Service;</li>
              <li><strong class="text-slate-200">Non-compliance</strong> -- it is understood as non-compliance of the Subject of the Digital Service with the Agreement regarding its provision;</li>
              <li><strong class="text-slate-200">Price List/Plan</strong> -- a document or information specifying the current price of the Service and Service, the variant of its operation;</li>
              <li><strong class="text-slate-200">Privacy Policy</strong> - a document containing information on the processing of personal data of Clients by the Service Provider;</li>
              <li><strong class="text-slate-200">Service Provider</strong> -- a term defined in § 1 section 3 of the Regulations;</li>
              <li><strong class="text-slate-200">Subject of Digital Service</strong> -- Account Service or Application Use Service;</li>
              <li><strong class="text-slate-200">Subscription Fee</strong> - a fee paid by the Client in advance in exchange for the Service of using the Application determined in accordance with the Price List/Plan in force at the time of its order;</li>
              <li><strong class="text-slate-200">Subscription Period</strong> - the period for which the Service Provider provides the Client with the Client to use the Application in accordance with the Plan selected by the Client;</li>
              <li><strong class="text-slate-200">Terms and Conditions or Regulations</strong> - a term defined in § 1 section 1 of the Regulations;</li>
              <li><strong class="text-slate-200">User</strong> -- a person who has an Account in the Application who is a Consumer or a person authorized to represent the Client and use the Application on his behalf;</li>
              <li><strong class="text-slate-200">User Content</strong> -- all data (including personal data), electronic files, information and materials recorded by the User on the Account, including generated contents.</li>
            </ol>
          </div>
        </section>

        <!-- § 3. Technical requirements -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            § 3. Technical requirements
          </h2>
          <div class="space-y-4 text-slate-300">
            <ol class="list-decimal list-outside ml-6 space-y-4">
              <li>To properly use the services provided by the Service Provider via the App, all of the following requirements must be satisfied jointly:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>a device with a monitor (screen);</li>
                  <li>connection to the Internet;</li>
                  <li>equipment allowing the use of Internet resources;</li>
                  <li>the current version of the Chrome web browser allowing hypertext documents to be displayed on the screen of the device, linked to the Internet via the World Wide Web service and supporting the JavaScript programming language, as well as accepting cookies;</li>
                  <li>an active e-mail account.</li>
                </ol>
              </li>
              <li>The use of viruses, bots, worms or other computer codes, files or programmes (in particular process automation scripts and applications or other codes, files or tools) is prohibited on the App.</li>
              <li>The Service Provider informs that it uses cryptographic protection of electronic transfer and digital content by means of appropriate logical, organisational and technical measures, in particular to third-party access to the data, including by SSL encryption, use of passwords and anti-virus or anti-malware programmes.</li>
              <li>The Service Provider advises that despite the safeguards referred to in Section 3 above, the use of the Internet and services provided by electronic means may be at risk of malicious software entering the Client's computer system and device or of third parties gaining access to the data on that device. To minimise the aforementioned risk, the Service Provider recommends the use of anti-virus programmes or means of protecting identification on the Internet.</li>
            </ol>
          </div>
        </section>

        <!-- § 4. General principles of Service provision -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            § 4. General principles of Service provision
          </h2>
          <div class="space-y-4 text-slate-300">
            <ol class="list-decimal list-outside ml-6 space-y-4">
              <li>The Client is obliged to use the services provided by the Service Provider in a manner consistent with the provisions of generally applicable law, the provisions of the Regulations, as well as with good customs.</li>
              <li>Providing illegal content by the User is prohibited.</li>
              <li>In the relationship between the parties and to the extent permitted by applicable law, the Clients holds all rights to the input data and, to the fullest extent possible, to the output data generated by them using the App. If the Client decides to publish the generated text, he/she is responsible for its content, including ensuring that it does not violate applicable law or these Terms and Conditions.</li>
              <li>The Client using the services provided by the Service Provider is obliged to provide only data (including personal data) consistent with the actual state. The Service Provider shall not be liable for the consequences of providing false or incomplete data by the Client.</li>
              <li>If the activities in the Application (in particular the conclusion of the Agreement) are performed by a natural person acting on behalf of the Client who is not a natural person, performing such an action is tantamount to submitting by the natural person performing such an action a statement that it is entitled to represent the Client. The Service Provider is entitled to require such a natural person to provide proof of its authorization to represent the Client, in particular a power of attorney document or an extract from the relevant register. In the case of performing an action on behalf of the Client despite the lack of authorization to represent him, the natural person performing this action bears the responsibility provided for in the provisions of the Civil Code.</li>
              <li>One Client may conclude only one Agreement for the provision of the Application Use Service.</li>
              <li>Prices of services and Packages are expressed in USD and are net and do not include VAT/gross value, i.e. include VAT.</li>
              <li>The conclusion of the Agreement for the provision of the Service for the use of the Application requires the prior conclusion of the Agreement for the provision of the Account Service.</li>
              <li>The following shall be considered a violation of the Regulations:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>providing illegal content by the User;</li>
                  <li>misuse of the Digital Services;</li>
                  <li>providing false or incomplete data by the Client or User;</li>
                  <li>a natural person performs an action on behalf of the Client despite the lack of authorization to represent him;</li>
                  <li>conclusion by the Client of more than one Agreement for the provision of the Application Use Service without the consent of the Service Provider.</li>
                </ol>
              </li>
              <li>In the event of a violation of the Regulations, the Service Provider may call the Client to remove it and set a deadline of not less than 7 (seven) days for this purpose.</li>
              <li>All statements, calls, notifications, and information referred to in the Regulations may be submitted by e-mail, unless a specific provision of the Regulations provides otherwise.</li>
              <li>The Client acknowledges that due to the nature of machine learning, the texts may not be unique for individual Clients. Moreover, the AI tool used to generate the content operates probabilistically. This means that the results of its operation may vary depending on many factors and are not always fully predictable. The generated content may not always fully meet the Client's expectations or precisely reflect the provided instructions. The Client accepts that the results may sometimes differ from the initial assumptions and will not make any claims against the Service Provider in this regard.</li>
              <li>The Client also acknowledges that the AI tool may, in rare situations, generate content that could be perceived as controversial, discriminatory, or offensive. The Service Provider do not support or promote any form of discrimination, hatred, or negative attitudes. Any such content generated by AI is a result of the unpredictability of the technology and does not reflect the views of the Service Provider and the Client will not make any claims against the Service Provider regarding such content. Clients are encouraged to report any content they consider controversial or inappropriate. Each report will be analyzed, and the system will be adjusted to minimize the risk of such content being generated in the future.</li>
              <li>The Client agrees to the use of the content by the Service Provider to the extent necessary for delivering and maintaining the service, complying with applicable law, and enforcing the rules of the Terms and Conditions.</li>
              <li>The Client accepts that the Application is intended only for adults and only for private (non-professional) use.</li>
            </ol>
          </div>
        </section>

        <!-- § 5. Account Service -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            § 5. Account Service
          </h2>
          <div class="space-y-4 text-slate-300">
            <ol class="list-decimal list-outside ml-6 space-y-4">
              <li>To conclude the Agreement for the provision of the Account Service, the User should perform the following actions:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>enter the website of the Application and then click on the "Sign in" -> "New Customer" buttons;</li>
                  <li>in the form that appears, enter the e-mail address (which is also the User's login) and password;</li>
                  <li>it is obligatory to mark the checkbox next to the statement of reading the Terms and Conditions and Privacy Policy and accepting their provisions;</li>
                  <li>click "Create Account".</li>
                </ol>
                <p class="mt-2">Alternatively, the User may register using their Google account by selecting the "Continue with Google" option and be verified using the Google Auth service.</p>
              </li>
              <li>The User gains access to the Account after confirming the e-mail address via an e-mail sent by the Service Provider (unless Google Auth was used as then the email is already verified). Registering is tantamount to the conclusion by the User of the Agreement for the provision of the Account Service.</li>
              <li>With the help of the Account, the User may in particular:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>store their data and the data of the Client represented by him/her;</li>
                  <li>purchase and use the functionalities included in the Application Use Service.</li>
                </ol>
              </li>
              <li>The Service Provider informs, and the Client acknowledges that maintaining compliance of the Account Service with the Agreement for the provision of the Account Service does not require the Client to install its updates.</li>
              <li>If the User is not granted access to the Account immediately after the conclusion of the Agreement for the provision of the Account Service, the User calls the Service Provider to immediately grant access to the Account. The summons referred to in the preceding sentence may be sent by e-mail to the address indicated in § 1 section 5 point 1 of the Regulations. If the Service Provider does not grant the User access to the Account immediately after receiving the request referred to in the preceding sentence, the User may withdraw from the Agreement for the provision of the Account Service.</li>
              <li>Notwithstanding the provisions of paragraph 6 above, in the absence of access to the Account to the User, the User may withdraw from the Agreement for the provision of the Account Service without calling the Service Provider to grant access to the Account, if at least one of the cases indicated in Article 43j paragraph 5 of the Act on consumer rights occurs.</li>
              <li>Notwithstanding the provisions of sections 6-7 above, the User may at any time and without giving a reason terminate the Agreement for the provision of the Account Service with a notice period of 14 (fourteen) days.</li>
              <li>Withdrawal by the User from the Agreement for the provision of the Account Service or its termination, regardless of the basis for this action, takes place by submitting to the Service Provider a statement of withdrawal from the Agreement for the provision of the Account Service or its termination. The statement referred to in the preceding sentence may be sent by e-mail to the address indicated in § 1 section 5 point 1 of the Regulations. The Service Provider deletes the Account immediately after receiving the statement referred to in the preceding sentence, or after the expiry of the notice period indicated in the Regulations.</li>
              <li>In the event that the User uses the Account in a manner contrary to the provisions of generally applicable law, the provisions of the Terms and Conditions or good practice, as well as the provision of illegal content by the User, the Service Provider may terminate the Agreement for the provision of the Account Service with a notice period of 7 (seven) days, by submitting a notice of termination to the User via e-mail. After the expiry of the notice period indicated in the preceding sentence, the Account is permanently deleted. During the notice period, the Service Provider may block the User's access to the Account for a period not exceeding 7 (seven) if necessary to prevent further infringements by the User.</li>
              <li>In the case of applying the measure referred to in section 11 above, the User against whom the measure has been applied may file an appeal on the terms described in § 8 of the Terms and Conditions.</li>
              <li>If, before withdrawing from the Agreement for the provision of the Account Service or its termination, regardless of the basis for this action, the Client concluded an Agreement for the provision of the Service for the use of the Application, withdrawal from the Agreement for the provision of the Account Service or its termination by either party is tantamount to simultaneous withdrawal from the Agreement for the provision of the Application Use Service and its termination.</li>
              <li>If the Service Provider terminates the Agreement for the provision of the Account Service to the User who is a Consumer is entitled to a refund of part of the remuneration in the amount proportional number of remaining AI interactions after the end of the notice period specified in the Agreement for the provision of the Account Service.</li>
              <li>The return of part of the remuneration referred to in paragraph 14 above takes place within 14 (fourteen) days from the date of termination of the Agreement for the provision of the Account Service by the Service Provider.</li>
              <li>Suspension of the provision of the Account Service (regardless of the basis for this action) is tantamount to deleting the Account and all Content stored on it.</li>
            </ol>
          </div>
        </section>

        <!-- § 6. Application Use Service -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            § 6. Application Use Service
          </h2>
          <div class="space-y-4 text-slate-300">
            <ol class="list-decimal list-outside ml-6 space-y-4">
              <li>Using the Application Use Service requires prior registration and the creation of an Account.</li>
              <li>Based on the Application Use Service, the Service Provider enables the Client (to the extent resulting from the Plan purchased by the Client) to use the following functions of the Application:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>the ability to create fairy tales, stories (by providing self-defined parameters necessary for generating the text, or by selecting predefined parameters within the App);</li>
                  <li>the ability to make generated contents public;</li>
                  <li>generating links to generated content;</li>
                  <li>storing generated contents;</li>
                  <li>receiving push and e-mail notifications;</li>
                </ol>
              </li>
              <li>Generated contents are by default "private," meaning that no one except the user who created them can view them. The user has the option to change the story's status to "public," which makes it accessible to anyone who has the link, even if they are not a user of the site.</li>
              <li>To conclude the Agreement for the provision of the Service for the use of the Application, the Client should perform the following actions:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>enter the Application and sing in into the Account;</li>
                  <li>choose one of the available Plans;</li>
                  <li>it is obligatory to tick the checkbox next to the declaration of consent to start providing the Application Use Service before the deadline for withdrawal from the Agreement for the provision of the Application Use Service);</li>
                  <li>it is obligatory to mark the checkbox next to the statement of reading the Terms and Conditions and Privacy Policy and accepting their provisions;</li>
                  <li>make a payment of remuneration for the Application Use Service (unless, in accordance with the Price List, the Client may use the Application Use Service free of charge);</li>
                  <li>click on the "Pay securely with Stripe" option.</li>
                </ol>
              </li>
              <li>Clicking the "Pay securely with Stripe" option and making the payment of remuneration (if required) is tantamount to the conclusion by the Client of the Agreement for the provision of the Service for the use of the Application.</li>
              <li>The contract for the provision of the Application Use Service is concluded for a definite period.</li>
              <li>The use of the Application Use Service after the end of the term of the Application Use Agreement requires the conclusion of another Agreement for the provision of the Application Use Service.</li>
              <li>After clicking the "Pay securely with Stripe" option, the Client should supplement the Account with the following data:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>name and surname;</li>
                  <li>billing address;</li>
                  <li>IP address (will be collected automatically)</li>
                </ol>
              </li>
              <li>The Service Provider informs, and the Client acknowledges that maintaining the compliance of the Application Use Service with the Agreement for the provision of the Application Use Service does not require to install its update.</li>
              <li>In the event of failing to grant access to the Application Use Service immediately after the conclusion of the Agreement for the provision of the Application Use Service, the Client informs the Service Provider to immediately grant access to the Application Use Service. The summons referred to in the preceding sentence may be sent by e-mail to the address indicated in § 1 section 5 point 1 of the Regulations. If the Service Provider does not grant the Client access to the Application Use Service immediately after receiving the information referred to in the preceding sentence, the Client may withdraw from the Agreement for the provision of the Application Use Service.</li>
              <li>Notwithstanding the provisions of paragraph 10 above, in the absence of granting the Client access to the Application Use Service, the Client may withdraw from the Agreement for the provision of the Application Use Service without calling the Service Provider to grant access to the Application Use Service, if at least one of the cases indicated in Article 43j paragraph 5 of the Act on consumer rights occurs.</li>
              <li>In the event of withdrawal by the Client from the Agreement for the provision of the Application Use Service pursuant to sections 10-11 above, the Service Provider shall immediately suspend the provision of the Application Use Service and return the proportionate amount of fee to the Client (if it has been paid) within 14 (fourteen) days from the date of receipt of the statement of withdrawal from the Agreement for the provision of the Application Use Service.</li>
              <li>In the event of a breach by the Client of the provisions of the Regulations and failure to remove this violation despite receiving the request referred to in § 4 section 10 of the Regulations, the Service Provider may terminate the Agreement for the provision of the Service for the use of the Application with a notice period of 7 (seven) days, by submitting a notice statement of termination to the Client by e-mail. After the expiry of the notice period indicated in the preceding sentence, the Service Provider suspends the provision of the Application Use Service. During the notice period, the Service Provider may block the CLient's access to the Application Use Service if it is necessary to prevent further violations by the Client.</li>
              <li>If the Service Provider terminates the Agreement for the provision of the Application Use Service, the Client who is a Consumer is entitled to a refund of part of the remuneration in the amount proportional to the number of AI interactions remaining after the end of the notice period remaining until the end of the Agreement specified in the Agreement for the provision of the Application Use Service.</li>
              <li>The refund of part of the remuneration referred to in the paragraphs 14 above takes place within 14 (fourteen) days from the date of termination of the Agreement for the provision of the Service for the use of the Application by the Service Provider.</li>
            </ol>
          </div>
        </section>
        <!-- § 7. Fees and Billing -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            § 7. Fees and Billing
          </h2>
          <div class="space-y-4 text-slate-300">
            <ol class="list-decimal list-outside ml-6 space-y-4">
              <li>Application Use Service is provided for a fee (unless the Plan stipulates otherwise).</li>
              <li>Unless a specific provision of the Regulations or individual arrangements with the Service Provider provide otherwise, all payments due to the Service Provider are made by the Clients using the payment systems made available as part of the Application.</li>
              <li>For the provision of Services, the Client is obliged to pay the Subscription Fee according to the amounts indicated in the Price List/Plan. Changes in prices indicated in the Price List and add-ons are announced on the Website and do not constitute changes to these Terms and Conditions.</li>
              <li>The date of payment is the date of booking the amount of the Subscription Fee due for the Service of using the Application on the Service Provider's bank account. After the payment is posted, the Service Provider sends to the Client's e-mail address a receipt.</li>
              <li>Payments for the Service of using the Application are made by the Client for each subsequent Subscription Period.</li>
              <li>The extension of the Subscription Period is tantamount to concluding another Agreement for the provision of the Application Use Service on the terms set out in the Regulations.</li>
              <li>The Client shall extend the Subscription Period by making a payment to the bank account indicated by the Service Provider for the use of the Application Use Service in the next Subscription Period.</li>
              <li>The next Subscription Period will be counted from the date of payment of the Subscription Fee towards the next Subscription Period.</li>
              <li>Lack of payment on the Service Provider's bank account for the use of the Services in the next Subscription Period will result in blocking access to the Application Use Service. In this case, the Client's data will be stored by the Service Provider for a period of 365 calendar days, counting from the day following the date of expiry of the Subscription Period. During this time, the Client may at any time resume using the Services by paying the Subscription Fee or delete his Account by contacting the Service Provider.</li>
              <li>During the Subscription Period, the user can use 450 interactions with AI. Unused interactions during a given Subscription Period do not carry over to subsequent subscription periods</li>
              <li>Once the Subscription Period ends and/or AI interaction is exhausted, the User can still access (and share) the User Content previously created during Subscription Period. In this case, the user cannot create new User Content.</li>
            </ol>
          </div>
        </section>

        <!-- § 8. User Content -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            § 8. User Content
          </h2>
          <div class="space-y-4 text-slate-300">
            <ol class="list-decimal list-outside ml-6 space-y-4">
              <li>It is prohibited to post User Content:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>containing false data, contrary to the law, the Terms and Conditions or good practice;</li>
                  <li>content that is prohibited by law, incites violence, hatred, or insults any group of people or individuals;</li>
                  <li>containing content that may infringe personal rights, copyrights, image rights or other rights of third parties;</li>
                  <li>content that is advertising, promotional, political, religious, or discriminatory;</li>
                  <li>containing content promoting competitive activities against the Service Provider.</li>
                </ol>
              </li>
              <li>Each person using the Application (hereinafter referred to as the "Reporting User") is entitled to report User Content that may violate the Terms and Conditions.</li>
              <li>You can make your application in the following ways:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>via e-mail to the following address: <a href="mailto:support@kidstelltales.com" class="text-blue-400 hover:text-blue-300">support@kidstelltales.com</a>;</li>
                  <li>in writing, preferably by registered mail, to the following address: Stelmachów 46b/2, 31-341 Kraków, Poland.</li>
                </ol>
              </li>
              <li>The application should include the following information:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>a sufficiently substantiated explanation of the reasons why the User Content in question constitutes illegal content;</li>
                  <li>A clear indication of the exact electronic location of the information, such as the exact URL or URLs, and, where applicable, additional information to identify the User Content, as appropriate to its type and the functionality of the Application;</li>
                  <li>the name and email address of the Reporter, with the exception of the report concerning information considered to be related to one of the offences referred to in Articles 3 to 7 of Directive 2011/93/EU;</li>
                  <li>a statement confirming the Reporting User's good faith belief that the information and allegations contained therein are correct and complete.</li>
                </ol>
              </li>
              <li>Upon receipt of the application, the Service Provider sends the Reporting User a confirmation of its receipt to the e-mail address provided by the Reporting User.</li>
              <li>In the event that the application does not contain the elements indicated in section 8 above or contains errors, the Service Provider may ask the Reporting User to supplement or correct the application within 14 days from the date of receipt of the above-mentioned request. In the event that the Reporting User fails to complete or correct the application within the time limit specified in the preceding sentence, the Service Provider may leave the application unexamined.</li>
              <li>The Service Provider verifies the submitted User Content within 14 days from the date of receipt of a complete and correct application. As part of the verification process, if necessary, we will ask you to submit any additional information or documents. Until we investigate your report, we may block the visibility of your User Content.</li>
              <li>After verifying the Application, the Service Provider:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>removes any User Content that violates the Terms and Conditions;</li>
                  <li>restores the User Content that does not violate the rules resulting from the Terms and Conditions (if its visibility was blocked at the stage of verification of the Application), giving the reasons for our decision.</li>
                </ol>
              </li>
              <li>In the event of removal of User Content the Service Provider shall immediately notify both the Reporting User and the User who published the removed User Content, providing the reasons for their decision.</li>
              <li>The justification of the Service Provider's decision includes:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>an indication of whether the decision includes the removal, disappearance, depositioning, or other measures referred to in the Terms and Conditions in relation to that User Content and, where applicable, the territorial scope of the decision and its duration;</li>
                  <li>the facts and circumstances on the basis of which the decision was made, including, where applicable, whether the decision was made on the basis of a report made by the Reporting User or on the basis of voluntary verification activities carried out at the initiative of the Service Provider and, where strictly necessary, the identity of the Reporting User;</li>
                  <li>where applicable, information about the use of automated means in decision-making, including whether a decision was made in relation to User Content detected or identified using automated tools;</li>
                  <li>if the decision relates to potentially prohibited User Content, an indication of the legal or contractual basis on which the decision is based and an explanation of the reasons why the User Content is considered to be prohibited on that basis;</li>
                  <li>clear and user-friendly information about their options to appeal the decision.</li>
                </ol>
              </li>
              <li>A User whose User Content has been removed or a Reporter who is refused removal by the Service Provider may appeal against the Service Provider's decision.</li>
              <li>You can file an appeal in the following ways:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>by e-mail -- to the following address: support@kidstelltales.com;</li>
                  <li>in writing, preferably by registered mail, to the following address: Stelmachów 46b/2, 31-341 Kraków, Poland.</li>
                </ol>
              </li>
              <li>The appeal should include:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>the name of the appellant,</li>
                  <li>contact details (e-mail address, correspondence address),</li>
                  <li>a detailed justification of why, in the appellant's review, the Service Provider's decision is erroneous and should be changed.</li>
                </ol>
              </li>
              <li>The Service Provider shall immediately confirm receipt of the appeal by sending a notification to the e-mail address provided by the Appellant.</li>
              <li>Appeals are considered within 14 days from the date of their receipt by the Seller's authorized team (these activities will not be performed in an automated manner, without human intervention).</li>
              <li>The Service provider shall notify the appellant of the decision made as a result of the appeal review via email, and if the reported content is simultaneously deemed prohibited, the Service Provider shall take actions against it as provided for in the Terms and Conditions.</li>
              <li>User bears full responsibility for the User Content and the consequences of its publication (including infringement of personal rights and intellectual property rights of third parties).</li>
            </ol>
          </div>
        </section>
        <!-- § 9. License -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            § 9. License
          </h2>
          <div class="space-y-4 text-slate-300">
            <ol class="list-decimal list-outside ml-6 space-y-4">
              <li>Upon conclusion of the Agreement for the provision of the Service for the use of the Application, the Service Provider grants the Client/User a non-exclusive license to use the App and its services under the terms and conditions set forth in the Terms and Conditions (hereinafter: "User License").</li>
              <li>User License is granted for the term of the Agreement.</li>
              <li>The license does not entitle the Client/User to grant further licenses.</li>
              <li>For the avoidance of doubt, the Service Provider informs that the Client/User is not authorized to lend, lease the Account, access the Application or Service Provider's materials without the Service Provider's prior written express consent.</li>
              <li>Use by the Client/User of the services available in the App contrary to the terms of the License shall constitute an infringement of the Service Provider's copyrights, entitling the Service Provider (depending on the nature of the infringement) to take legal action against the Client/User.</li>
            </ol>
          </div>
        </section>

        <!-- § 10. Right of withdrawal -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            § 10. Right of withdrawal
          </h2>
          <div class="space-y-4 text-slate-300">
            <ol class="list-decimal list-outside ml-6 space-y-4">
              <li>Pursuant to Article 27 et seq. of the Act on consumer rights, the Client has the right to withdraw from the Agreement without giving any reason within 14 (fourteen) days from the date of its conclusion.</li>
              <li>The right to withdraw from the Agreement, the Client performs by submitting to the Service Provider a statement of withdrawal from the Agreement. To meet the deadline for withdrawal from the contract, it is sufficient to send a statement before the deadline referred to in paragraph 2 above.</li>
              <li>The statement of withdrawal from the Agreement may be submitted by the Client in any form, in particular on the form constituting Annex 2 to the Act on consumer rights.</li>
              <li>In the event of submitting a statement of withdrawal from the Agreement, the Service Provider shall immediately send the Client a confirmation of its receipt by e-mail.</li>
              <li>The Service Provider informs, and the Client acknowledges that the right to withdraw from the Agreement referred to in this paragraph is not entitled to the Client in relation to the Agreement for the provision of a service that has been fully performed (pursuant to Article 38 paragraph 1 point 1 of the Act on consumer rights).</li>
              <li>If the withdrawal from the Agreement concerns the Agreement for the provision of a service that has not been fully performed, the Service Provider shall return to the Client the price of the Package reduced by the value of services used by the Client until the withdrawal from the Agreement. The refund of the price takes place within 14 (fourteen) days from the date of receipt by the Service Provider of the declaration of withdrawal from the Agreement and it is made using the same method of payment as used by the Client in the original transaction unless the Client expressly agrees to a different solution. The Client does not bear the costs of returning the payment made.</li>
            </ol>
          </div>
        </section>

        <!-- § 11. Liability of the Service Provider -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            § 11. Liability of the Service Provider
          </h2>
          <div class="space-y-4 text-slate-300">
            <ol class="list-decimal list-outside ml-6 space-y-4">
              <li>The Service Provider undertakes to provide services with due diligence.</li>
              <li>To the extent permitted by the provisions of the Civil Code and the Act on consumer rights, the Service Provider shall not be liable to the Clients for the consequences of:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>use by the Client/User of any of the services available in the Application contrary to their intended use/purpose, good morals, the rights of third parties or the law;</li>
                  <li>introduction by Client/User of data or content in violation of good morals, laws, rights of third parties;</li>
                  <li>providing incorrect or false data by Users;</li>
                  <li>the effects of using data authorizing access to the Account Service by third parties if these persons came into possession of such data as a result of their disclosure by the Client/User or as a result of their insufficient protection by the Client against access by such persons.</li>
                </ol>
              </li>
              <li>The Service Provider's liability for damages incurred by the Client as a result of non-performance or improper performance of the obligation by the Service Provider is limited to damage in the form of actual losses incurred by the Client and up to an amount not exceeding the total amount of the Service Provider's remuneration paid by the Client for the last 6 (six) months before the damage occurred.</li>
              <li>To the extent permitted by the provisions of the Civil Code and the Act on consumer rights, the Service Provider shall not be liable for disruptions in the functioning of the Application resulting from:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>force majeure;</li>
                  <li>necessary conservation and modernization works carried out in the Application;</li>
                  <li>reasons attributable to the Client;</li>
                  <li>reasons beyond the Service Provider's control, in particular actions of third parties, for which the Service Provider is not responsible.</li>
                </ol>
              </li>
              <li>The Service Provider undertakes to carry out the works referred to in section 4 point 2 above in the least burdensome way possible for the Clients and, if possible, to inform them in advance about the planned works.</li>
              <li>The Service Provider undertakes, as far as possible, to remove disruptions in the functioning of the Application on an ongoing basis.</li>
            </ol>
          </div>
        </section>
        <!-- § 12. Complaints regarding the Subject of the Digital Service -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            § 12. Complaints regarding the Subject of the Digital Service
          </h2>
          <div class="space-y-4 text-slate-300">
            <ol class="list-decimal list-outside ml-6 space-y-4">
              <li>The provisions of this § 12 shall apply only to:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>Agreements for the provision of the Account Service, Agreements for the provision of the Service for the use of the Application, unless a specific provision of the Regulations applies only to one of these Agreements;</li>
                  <li>Non-compliance of the Account Service with the Agreement for the provision of the Account Service, Non-compliance of the Application Use Service with the Agreement for the provision of the Application Use Service.</li>
                </ol>
              </li>
              <li>The Subject of the Digital Service Provided to the Client by the Service Provider must comply with the Agreement for its delivery:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>at the time of its delivery -- if the Subject of the digital service is delivered once or in parts;</li>
                  <li>throughout the entire period of delivery of a given Digital Service Subject -- if the Digital Subject is delivered continuously.</li>
                </ol>
              </li>
              <li>The Service Provider shall be liable for Non-compliance:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>existing at the time of delivery of the Digital Service Subject and disclosed within 2 (two) years from that moment - if the Digital Service Subject is delivered once or in parts;</li>
                  <li>disclosed during the period of delivery of a given Digital Service Subject - if the Subject of the digital service is delivered continuously.</li>
                </ol>
              </li>
              <li>In the event of disclosure of Non-conformities, the Client may file a complaint containing a request to bring the Subject of the digital service into compliance with the Agreement regarding its delivery.</li>
              <li>The complaint is submitted by e-mail to the address indicated in § 1 section 5 point 1 of the Regulations.</li>
              <li>The complaint should include:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>name and surname of the Client;</li>
                  <li>e-mail address;</li>
                  <li>a description of the Incompatibility revealed;</li>
                  <li>a request to bring the Subject of the digital service into compliance with the Agreement regarding its delivery.</li>
                </ol>
              </li>
              <li>The Service Provider may refuse to bring the Subject of the digital service into compliance with the Agreement regarding its delivery, if this is impossible or would require the Service Provider to incur excessive costs.</li>
              <li>After considering the complaint, the Service Provider shall provide the Client with a response to the complaint, in which:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>acknowledges the complaint and indicates the planned date of bringing the Subject of the digital service into compliance with the Agreement regarding its delivery;</li>
                  <li>refuses to bring the Subject of the digital service into compliance with the Agreement regarding its delivery for the reasons indicated in paragraph 7 above;</li>
                  <li>rejects the complaint because it is unfounded.</li>
                </ol>
              </li>
              <li>The Service Provider shall respond to the complaint by e-mail within 14 (fourteen) days from the date of its receipt.</li>
              <li>If the complaint is accepted, the Service Provider shall, at its own expense, bring the Subject of the digital service into compliance with the Agreement regarding its delivery within a reasonable time from the receipt of the complaint and without undue inconvenience to the Client, considering the nature of the Digital Service Subject and the purpose for which it is used. The planned date of bringing the Subject of the digital service into compliance with the Agreement regarding its delivery is indicated by the Service Provider in response to the complaint.</li>
              <li>In the event of disclosure of Nonconformities, subject to section 14 below, the Client may submit to the Service Provider a statement on price reduction or withdrawal from the Agreement when:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>bringing the Subject of the Digital Service into conformity with the Agreement for its delivery is impossible or requires excessive costs;</li>
                  <li>The Service Provider has not brought the Subject of the digital service into compliance with the Agreement regarding its delivery in accordance with paragraph 10 above;</li>
                  <li>The non-compliance persists despite the Service Provider's attempt to bring the Subject of the digital performance into conformity with the Agreement for its delivery;</li>
                  <li>The non-compliance is so significant that it justifies withdrawal from the Agreement for the delivery of a given Digital Service Subject without first requesting the Service Provider to bring the Digital Service Subject into compliance with the Agreement regarding its delivery;</li>
                  <li>It is clear from the Service Provider's statement or circumstances that the Service Provider will not bring the Subject of the digital service into compliance with the Agreement regarding its delivery within a reasonable time or without undue inconvenience to the Client.</li>
                </ol>
              </li>
              <li>A statement on price reduction or withdrawal from the Agreement may be submitted by e-mail to the address indicated in § 1 section 4 point 1 of the Regulations.</li>
              <li>The statement on price reduction or withdrawal from the Agreement should contain:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>name and surname of the Client;</li>
                  <li>e-mail address;</li>
                  <li>the date of delivery of the Digital Service Subject;</li>
                  <li>a description of the Incompatibility revealed;</li>
                  <li>indication of the reason for making the declaration, chosen from among the reasons indicated in paragraph 11 above;</li>
                  <li>statement on price reduction, together with an indication of the reduced price or a statement of withdrawal from the Agreement.</li>
                </ol>
              </li>
              <li>The Client may not submit to the Service Provider a statement on price reduction if the Incompatibility concerns the Account Service provided free of charge.</li>
              <li>The Service Provider is not entitled to demand payment for the time during which the Service of Use of the Application is inconsistent with the Agreement for its provision, even if the Client used it before withdrawing from the contract concerning it.</li>
              <li>The reduced price must remain in such proportion to the price resulting from the Agreement for the provision of the Application Use Service in which the value of the Application Use Service inconsistent with the agreement for its provision remains to the value of the Use Service in accordance with the Agreement for the provision of the Application Use Service (in accordance with the Package purchased by the Client).</li>
              <li>The Service Provider shall reimburse the Client the amounts due as a result of exercising the right to reduce the price or withdraw from the Agreement for the provision of the Application Use Service immediately, no later than within 14 (fourteen) days from the date of receipt of the statement on price reduction or withdrawal from the Agreement for the provision of the Application Use Service.</li>
              <li>In the event of withdrawal by the Client from the Agreement for the provision of the Account Service, the Service Provider deletes the Account immediately after receiving a statement of withdrawal from the Agreement for the provision of the Account Service.</li>
              <li>If, before withdrawing from the Agreement for the provision of the Account Service, the Client concluded an Agreement for the provision of the Service for the use of the Application, withdrawal from the Agreement for the provision of the Account Service is tantamount to simultaneous withdrawal from the Agreement for the provision of the Service for the use of the Application on the basis of the provisions of this § 12. Withdrawal from the Agreement for the provision of the Application Use Service in the mode indicated in this paragraph 19 has the same effects as withdrawal from the contract due to the occurrence of Incompatibilities.</li>
              <li>Pursuant to Article 34(1a) of the Act on consumer rights, if the Client withdraws from the Agreement regarding the delivery of the Subject of the Digital Service, the Client is obliged to cease using this Digital Service Subject and make it available to third parties.</li>
            </ol>
          </div>
        </section>
        <!-- § 13. Intellectual property of the Service Provider -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            § 13. Intellectual property of the Service Provider
          </h2>
          <div class="space-y-4 text-slate-300">
            <ol class="list-decimal list-outside ml-6 space-y-4">
              <li>The Service Provider owns all copyrights to the App, excluding elements of the App that are libraries derived from so-called open source software (open source libraries) and to the extent that the App uses the AI Tool.</li>
              <li>All license and intellectual property rights related to the implementation of the services and the App remain the exclusive domain of the Service Provider, with the exception of the rights to the AI Tool, which remain the property of its creators and are made available under license.</li>
              <li>The layout of the App, as well as the photos, graphic motifs, logos, multimedia materials and other content posted in the App, which are at least a manifestation of creative activity of an individual nature, constitute works within the meaning of the provisions of the Act of February 4, 1994 on Copyright and Related Rights, and are subject to legal protection under this Act, as well as the Act of 30 June 2000 - Industrial Property Law, the Act of 16 April 1993 on combating unfair competition and other provisions of generally applicable law, including the provisions of European Union law.</li>
              <li>Any use of the Provider's intellectual property without its prior, express permission is prohibited.</li>
            </ol>
          </div>
        </section>

        <!-- § 14. Use of generative artificial intelligence -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            § 14. Use of generative artificial intelligence
          </h2>
          <div class="space-y-4 text-slate-300">
            <ol class="list-decimal list-outside ml-6 space-y-4">
              <li>The Client is aware that the Service Provider offers Application Use Service based on artificial intelligence solutions, and therefore the Client acknowledges that he/she is aware that he may interact with the artificial intelligence system within the Application.</li>
              <li>The Client acknowledges that AI tool (hereinafter: "AI Tool") have been trained (through machine learning) in whole or in part using data and materials in relation to which third parties may have intellectual property rights (in particular, copyright and industrial property rights).</li>
              <li>The Client further acknowledges that the content generated by the AI Tool (hereinafter: "AI Materials") may unintentionally infringe the intellectual property rights of third parties and agrees to use such content responsibly and in compliance with applicable laws.</li>
              <li>The Client also acknowledges the possibility of the occurrence of restrictions on the use of the AI Material referred to in paragraph above and does not object to this fact. The Service Provider shall not be liable for the use of AI Material and AI Tool by the Client in a manner contrary to the provisions of the Terms and Conditions or in violation of the rights of any third parties.</li>
              <li>The Service Provider shall make every effort to make the services as efficient, accessible and stable as possible, and to comply with the provisions of Regulation (EU) 2024/1689 of the European Parliament and of the Council of June 13, 2024 on establishing harmonized rules for artificial intelligence and amending Regulations (EC) No. 300/2008, (EU) No. 167/2013, (EU) No. 168/2013, (EU) 2018/858, (EU) 2018/1139 and (EU) 2019/2144, and Directives 2014/90/EU, (EU) 2016/797 and (EU) 2020/1828 (Artificial Intelligence Act).</li>
              <li>The Service Provider undertakes to label the functions powered by the artificial intelligence model, and to make information about the principles of the model available to the Service Provider upon request.</li>
              <li>The Client is aware of the terms and conditions of data collection and processing used by the AI Tool, i.e. the Anthropic Claude language model. The Service Provider informs Clients about a significant change in the Service by placing a message informing about the changes in the Account.</li>
            </ol>
          </div>
        </section>

        <!-- § 15. Processing of personal data -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            § 15. Processing of personal data
          </h2>
          <div class="space-y-4 text-slate-300">
            <p>Information on the processing of personal data by the Service Provider can be found in the Privacy Policy available at: <a href="/privacy" class="text-blue-400 hover:text-blue-300">https://kidstelltales.com/privacy</a>.</p>
          </div>
        </section>

        <!-- § 16. Out-of-court dispute resolution -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            § 16. Out-of-court dispute resolution
          </h2>
          <div class="space-y-4 text-slate-300">
            <ol class="list-decimal list-outside ml-6 space-y-4">
              <li>The Client has the option of using out-of-court complaint and redress methods.</li>
              <li>Detailed information on the possibility of using out-of-court complaint and redress methods by the Client and the rules of access to these procedures are available at the offices and on the websites:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>poviat (municipal) consumer ombudsmen, social organizations whose statutory tasks include consumer protection;</li>
                  <li>Provincial Inspectorates of Trade Inspection;</li>
                  <li>Office of Competition and Consumer Protection.</li>
                </ol>
              </li>
              <li>The Client may also use the platform for online dispute resolution (ODR) available at: http://ec.europa.eu/consumers/odr/.</li>
            </ol>
          </div>
        </section>
        <!-- § 17. Changing the Digital Service -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            § 17. Changing the Digital Service
          </h2>
          <div class="space-y-4 text-slate-300">
            <ol class="list-decimal list-outside ml-6 space-y-4">
              <li>Under this § 16:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>The Account Service and the Application Use Service are hereinafter jointly referred to as the "Service", unless a specific provision of the Regulations applies only to one of these services;</li>
                  <li>The Agreement for the provision of the Account Service and the Agreement for the provision of the Application Use Service are hereinafter jointly referred to as the "Service Provision Agreement", unless a specific provision of the Regulations applies to only one of these agreements.</li>
                </ol>
              </li>
              <li>The Service Provider may change the Service in the case of:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>the need to adapt the Service to newly created devices or software used by the Clients to use the Service;</li>
                  <li>the Service Provider decides to improve the Service by adding new functionalities or modifying existing functionalities;</li>
                  <li>legal obligation to make changes, including the obligation to adapt the Service to the current legal status.</li>
                </ol>
              </li>
              <li>Changing the Service may not involve any costs on the part of the Client.</li>
              <li>The Service Provider informs the Clients about significant changes to the Service by placing a message on the Account informing about the changes. Independently, information about the change may be sent to the Clients via e-mail.</li>
              <li>If the change of the Service will significantly and negatively affect the access to the Service of the Client, the Service Provider is obliged to inform the Client about:
                <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                  <li>the nature and timing of the change, and</li>
                  <li>the Client's right to terminate the Agreement for the provision of the Service with immediate effect within 30 (thirty) days of making the change.</li>
                </ol>
              </li>
              <li>The information referred to in paragraph 5 above shall be sent by the Service Provider to the Clients via e-mail, no later than 7 (seven) days before the change.</li>
              <li>Termination by the Client of the Agreement for the provision of the Service pursuant to paragraph 5 point 2 above takes place by submitting to the Service Provider a statement on termination of the Agreement for the provision of the Service. The statement referred to in the preceding sentence may be sent by e-mail to the address indicated in § 1 section 4 point 1 of the Regulations.</li>
              <li>Termination of the Agreement for the provision of Services pursuant to section 5 point 2 above has the same effects as § 13 of the Regulations provides in the event of withdrawal from the Agreement for the provision of Services due to the occurrence of Non-conformity.</li>
            </ol>
          </div>
        </section>

        <!-- § 18. Price list -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            § 18. Price list
          </h2>
          <div class="space-y-4 text-slate-300">
            <ol class="list-decimal list-outside ml-6 space-y-4">
              <li>The current Price List/Plan is available at: <a href="/purchase" class="text-blue-400 hover:text-blue-300">https://kidstelltales.com/purchase</a></li>
              <li>The price list/Plan may include the possibility of free use of selected functionalities of the Application.</li>
              <li>The Service Provider may change the Price List/Plan at any time.</li>
              <li>The change of the Price List/Plan in no way affects the amount of fees specified in the Agreements concluded before the change of the Price List/Plan.</li>
            </ol>
          </div>
        </section>

        <!-- § 19. Final provisions -->
        <section class="mb-12">
          <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
            § 19. Final provisions
          </h2>
          <div class="space-y-4 text-slate-300">
            <ol class="list-decimal list-outside ml-6 space-y-4">
              <li>The current version of the Terms and Conditions is effective from 18.12.2024.</li>
              <li>The Terms and Conditions are governed by Polish law. The choice of Polish law made in the preceding sentence shall not, however, deprive the Consumer of protection under the provisions of foreign law which cannot be excluded by contract and which would be applicable in the absence of the choice of Polish law made in the preceding sentence.</li>
              <li>Any disputes under these Terms and Conditions shall be resolved by amicable negotiations, and in the event of no agreement - before a common court competent for the seat of the Service Provider.</li>
              <li>In matters not covered by the Regulations, the provisions of generally applicable Polish law shall apply.</li>
            </ol>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useMetaTags } from "@/components/useMetaTags"

const route = useRoute()

onMounted(() => {
  useMetaTags({
    title: 'Terms and Conditions',
    path: route.path,
  })
})
</script>