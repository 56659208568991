<template>
  <div class="p-6 md:p-12">
    <!-- Welcome Message -->
    <div class="max-w-md mx-auto text-center mb-8">
      <h1 class="text-3xl font-display font-bold text-slate-100 mb-2">Welcome to Kids Tell Tales</h1>
      <p class="text-slate-400">Sign in or create an account to get started</p>
    </div>

    <!-- Main Content -->
    <div class="max-w-md mx-auto space-y-6">
      <!-- Initial Options -->
      <div v-if="!showForm" class="space-y-6">
        <!-- Email Section -->
        <div class="space-y-3">
          <h2 class="text-lg font-medium text-slate-200 mb-4">Continue with Email</h2>

          <!-- Action Buttons with improved visual hierarchy -->
          <button
              @click="startEmailSignIn"
              class="w-full p-4 bg-blue-600 hover:bg-blue-500
                 text-white rounded-lg font-medium
                 transition-all duration-200
                 flex items-center justify-center space-x-3
                 group shadow-lg hover:shadow-blue-500/25"
          >
            <LogIn class="h-5 w-5 group-hover:scale-110 transition-transform"/>
            <span>Existing Customer</span>
          </button>

          <button
              @click="startEmailSignUp"
              class="w-full p-4 bg-slate-700 hover:bg-slate-600
                 text-white rounded-lg font-medium
                 transition-all duration-200
                 flex items-center justify-center space-x-3
                 group shadow-lg hover:shadow-slate-500/25"
          >
            <UserPlus class="h-5 w-5 group-hover:scale-110 transition-transform"/>
            <span>New Customer</span>
          </button>
        </div>

        <!-- Divider -->
        <div class="relative">
          <div class="absolute inset-0 flex items-center">
            <div class="w-full border-t border-slate-700"></div>
          </div>
          <div class="relative flex justify-center text-sm">
            <span class="px-2 text-slate-500">OR</span>
          </div>
        </div>

        <!-- Google Button -->
        <button
            @click="signInWithGoogle"
            class="w-full p-4 bg-white hover:bg-slate-100
                 text-slate-900 rounded-lg font-medium
                 transition-all duration-200
                 flex items-center justify-center space-x-3
                 group shadow-lg"
        >
          <svg
              class="h-6 w-6 transition-transform group-hover:scale-110"
              viewBox="0 0 24 24"
              fill="currentColor"
          >
            <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
            <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
            <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
            <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
          </svg>
          <span>Continue with Google</span>
        </button>
      </div>

      <!-- Email Form -->
      <form v-else-if="!successMessage" @submit.prevent="handleEmailAuth" class="space-y-4">
        <div>
          <label for="email" class="block text-sm font-medium text-slate-300 mb-1">Email</label>
          <input
              id="email"
              v-model="email"
              type="email"
              required
              class="w-full px-4 py-2 bg-slate-800 border border-slate-600
                     text-slate-300 placeholder-slate-500 rounded-lg
                     focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20
                     shadow-lg"
              placeholder="Enter your email"
              @keydown.tab="handleEmailTab"
          />
        </div>

        <div v-if="!isPasswordReset">
          <div class="flex justify-between mb-1">
            <label for="password" class="block text-sm font-medium text-slate-300">Password</label>
            <button
                v-if="!isSignUp"
                type="button"
                tabindex="3"
                @click="startPasswordReset"
                class="text-sm text-blue-400 hover:text-blue-300"
            >
              Forgot password?
            </button>
          </div>
          <input
              id="password"
              ref="passwordInput"
              v-model="password"
              type="password"
              required
              tabindex="2"
              class="w-full px-4 py-2 bg-slate-800 border border-slate-600
                     text-slate-300 placeholder-slate-500 rounded-lg
                     focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20
                     shadow-lg"
              placeholder="Enter your password"
              minlength="6"
          />
        </div>

        <div v-if="isSignUp">
          <label for="confirmPassword" class="block text-sm font-medium text-slate-300 mb-1">
            Confirm Password
          </label>
          <input
              id="confirmPassword"
              ref="confirmPasswordInput"
              v-model="confirmPassword"
              type="password"
              required
              tabindex="3"
              class="w-full px-4 py-2 bg-slate-800 border border-slate-600
               text-slate-300 placeholder-slate-500 rounded-lg
               focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20
               shadow-lg"
              placeholder="Confirm your password"
              minlength="6"
          />
        </div>

        <div v-if="isPasswordReset" class="text-sm text-slate-300">
          Enter your email and we'll send you a link to reset your password.
        </div>

        <div v-if="isSignUp" class="space-y-4 mt-4">

          <!-- Terms and Conditions Checkbox -->
          <label class="flex items-start space-x-3 cursor-pointer group pt-4">
            <div class="relative flex items-start">
              <div class="bg-slate-800/50 border border-slate-600/50 rounded w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 mt-0.5
          group-hover:border-blue-500/50 transition-colors"
                   :class="{'border-red-500/50': !termsAccepted && error}">
                <input
                    type="checkbox"
                    v-model="termsAccepted"
                    class="opacity-0 absolute cursor-pointer"
                >
                <svg
                    class="fill-current w-3 h-3 text-blue-400 pointer-events-none"
                    viewBox="0 0 20 20"
                    :class="{ 'hidden': !termsAccepted }"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                </svg>
              </div>
            </div>
            <div class="flex-1">
      <span class="select-none flex items-center space-x-2">
        <span> I have read and agree to the
          <a href="/terms" target="_blank" class="text-blue-400 hover:text-blue-300">Terms and Conditions</a> and
          <a href="/privacy" target="_blank" class="text-blue-400 hover:text-blue-300">Privacy Policy</a>.
            <span class="text-red-400 text-sm">*</span>
        </span>
      </span>
            </div>
          </label>

          <!-- Marketing Consent Checkbox -->
          <label class="flex items-start space-x-3 cursor-pointer group">
            <div class="relative flex items-start">
              <div class="bg-slate-800/50 border border-slate-600/50 rounded w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 mt-0.5
          group-hover:border-blue-500/50 transition-colors">
                <input
                    type="checkbox"
                    v-model="marketingAccepted"
                    class="opacity-0 absolute cursor-pointer"
                >
                <svg
                    class="fill-current w-3 h-3 text-blue-400 pointer-events-none"
                    viewBox="0 0 20 20"
                    :class="{ 'hidden': !marketingAccepted }"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                </svg>
              </div>
            </div>
            <div class="flex-1">
<span class="select-none">
  Sign me up for updates and special offers. I accept the
  <a href="/newsletter-terms" target="_blank" class="text-blue-400 hover:text-blue-300">Newsletter Terms</a>.
</span>
            </div>
          </label>
        </div>
        <div class="flex flex-col gap-3">
          <button
              type="submit"
              :disabled="isLoading || !canSubmit"
              class="w-full p-4 bg-blue-600 hover:bg-blue-500 disabled:bg-blue-600/50
                     text-white rounded-lg font-medium shadow-lg
                     transition-all duration-200
                     flex items-center justify-center space-x-3
                     group hover:shadow-blue-500/25"
          >
            <div v-if="isLoading" class="flex items-center space-x-2">
              <span>{{ loadingMessage }}</span>
              <LoadingDots />
            </div>
            <div v-else class="flex items-center space-x-2">
              <component :is="submitIcon" class="h-5 w-5 group-hover:scale-110 transition-transform"/>
              <span>{{ submitButtonText }}</span>
            </div>
          </button>

          <button
              type="button"
              @click="resetForm"
              class="text-sm text-blue-400 hover:text-blue-300"
          >
            Back
          </button>
        </div>

        <!-- Error Message -->
        <div v-if="error"
             class="p-4 bg-red-600/10 border border-red-500/20 rounded-lg shadow-lg">
          <div class="flex items-start space-x-3">
            <AlertTriangle class="h-5 w-5 text-red-400 flex-shrink-0 mt-0.5"/>
            <p class="text-red-400 text-sm">{{ error }}</p>
          </div>
        </div>
      </form>

      <!-- Success Message -->
      <div v-if="successMessage" class="space-y-6">
        <div class="p-4 bg-emerald-600/10 border border-emerald-500/20 rounded-lg shadow-lg">
          <div class="flex items-start space-x-3">
            <CheckCircle class="h-5 w-5 text-emerald-400 flex-shrink-0 mt-0.5"/>
            <p class="text-emerald-400 text-sm">{{ successMessage }}</p>
          </div>
        </div>

        <button
            @click="resetForm"
            class="w-full p-4 bg-slate-700 hover:bg-slate-600
                 text-white rounded-lg font-medium shadow-lg
                 transition-all duration-200
                 flex items-center justify-center space-x-3
                 group hover:shadow-slate-500/25"
        >
          <ArrowLeft class="h-5 w-5 group-hover:-translate-x-1 transition-transform"/>
          <span>{{user ? "Continue" : "Back to Sign In"}}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, computed, onMounted} from 'vue'
import {getCurrentUser, useCurrentUser, useFirebaseAuth} from 'vuefire'
import {
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
} from 'firebase/auth'
import {useRoute, useRouter} from 'vue-router'
import LoadingDots from './LoadingDots.vue'
import { useSessionStore } from '@/store/session'
import {
  LogIn,
  UserPlus,
  AlertTriangle,
  Send,
  CheckCircle,
  ArrowLeft
} from 'lucide-vue-next'
import { getFirebaseErrorMessage } from "@/utils/firebase"
import {useMetaTags} from "@/components/useMetaTags";
import {useEmailValidation} from "@/composables/useEmailValidation";

const user = useCurrentUser()
const auth = useFirebaseAuth()
const router = useRouter()
const route = useRoute()
const sessionStore = useSessionStore()

// Form state
const showForm = ref(false)
const isSignUp = ref(false)
const isPasswordReset = ref(false)
const email = ref('')
const password = ref('')
const confirmPassword = ref('')
const error = ref('')
const successMessage = ref('')
const isLoading = ref(false)
const confirmPasswordInput = ref<HTMLInputElement | null>(null)
const passwordInput = ref<HTMLInputElement | null>(null)

const termsAccepted = ref(false)
const marketingAccepted = ref(false)

const { validateEmail } = useEmailValidation()

// Computed properties
const loadingMessage = computed(() => {
  if (isPasswordReset.value) return 'Sending reset link...'
  return isSignUp.value ? 'Creating account...' : 'Signing in...'
})

const submitButtonText = computed(() => {
  if (isPasswordReset.value) return 'Send Reset Link'
  return isSignUp.value ? 'Create Account' : 'Sign In'
})

const submitIcon = computed(() => {
  if (isPasswordReset.value) return Send
  return isSignUp.value ? UserPlus : LogIn
})

const canSubmit = computed(() => !isSignUp.value || termsAccepted.value)

// Add function to handle "agree to all"
const agreeToAll = () => {
  termsAccepted.value = true
  marketingAccepted.value = true
}

// Methods
function startEmailSignIn() {
  showForm.value = true
  isSignUp.value = false
  isPasswordReset.value = false
  resetFormFields()
}

function startEmailSignUp() {
  showForm.value = true
  isSignUp.value = true
  isPasswordReset.value = false
  resetFormFields()
}

function startPasswordReset() {
  isPasswordReset.value = true
  resetFormFields()
}

async function resetForm() {
  const user = await getCurrentUser()
  if (user) {
    router.push("/")
  }

  showForm.value = false
  isSignUp.value = false
  isPasswordReset.value = false
  resetFormFields()
}

function resetFormFields() {
  email.value = ''
  password.value = ''
  confirmPassword.value = ''
  error.value = ''
  successMessage.value = ''
}

function handleEmailTab(e: KeyboardEvent) {
  if (e.key === 'Tab' && !e.shiftKey) {
    e.preventDefault()
    passwordInput.value?.focus()
  }
}

function handlePasswordTab(e: KeyboardEvent) {
  if (!e.shiftKey && isSignUp.value) { // Only handle forward tab in signup mode
    e.preventDefault()
    confirmPasswordInput.value?.focus()
  }
}

async function signInWithGoogle() {
  try {
    sessionStore.clearStore()
    const provider = new GoogleAuthProvider()
    await signInWithPopup(auth, provider)
    router.push('/')
  } catch (e) {
    handleError(e)
  }
}

async function handleEmailAuth() {
  if (isLoading.value) return
  error.value = ''
  successMessage.value = ''
  isLoading.value = true

  try {
    if (isPasswordReset.value) {
      await handlePasswordReset()
    } else if (isSignUp.value) {
      await handleSignUp()
    } else {
      await handleSignIn()
    }
  } catch (e) {
    handleError(e)
  } finally {
    isLoading.value = false
  }
}

onMounted(async () => {
  sessionStore.healthcheck()

  useMetaTags({
    title: 'Login',
    path: route.path,
  })
})

async function handleSignIn() {
  sessionStore.clearStore()
  await signInWithEmailAndPassword(auth, email.value, password.value)
  router.push('/')
}

async function handleSignUp() {
  if (password.value !== confirmPassword.value) {
    error.value = "Passwords don't match"
    return
  }

  if (!termsAccepted.value) {
    error.value = "You must accept the Terms and Conditions"
    return
  }

  const result = await validateEmail(email.value, true)
  if(!result.isValid) {
    error.value = result.error!
    return
  }

  const credential = await createUserWithEmailAndPassword(auth, email.value, password.value)
  await sendEmailVerification(credential.user)
  await sessionStore.setMarketingPreferences(marketingAccepted.value, termsAccepted.value)
  successMessage.value = 'Account created! Please check your email to verify your account.'
}

async function handlePasswordReset() {
  await sendPasswordResetEmail(auth, email.value)
  successMessage.value = 'Password reset link has been sent to your email if you are an existing customer'
}

function handleError(e: any) {
  const message = getFirebaseErrorMessage(e)

  if (e?.code === 'auth/popup-closed-by-user') {
    error.value = ''
    return
  }

  error.value = message
}
</script>