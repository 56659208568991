<template>
  <div class="min-h-screen">
    <div class="p-6 md:p-12">
      <div class="max-w-4xl mx-auto">
        <!-- Header -->
        <h1 class="text-3xl md:text-4xl font-display font-bold text-slate-100 mb-8">
          Newsletter Terms and Conditions
        </h1>

        <div class="prose prose-invert max-w-none">
          <!-- Table of Contents -->
          <section class="mb-12">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">Table of contents</h2>
            <ol class="list-decimal list-outside ml-6 space-y-2 text-slate-300">
              <li>General provisions</li>
              <li>Definitions</li>
              <li>Technical requirements</li>
              <li>General terms of service</li>
              <li>Newsletter Subscription Agreement</li>
              <li>Complaints concerning Newsletter</li>
              <li>Personal data processing</li>
              <li>Out-of-court dispute resolution</li>
              <li>Amendments to the Terms and Conditions</li>
              <li>Final provisions</li>
            </ol>
          </section>

          <!-- § 1. General provisions -->
          <section class="mb-12">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
              § 1. General provisions
            </h2>
            <div class="space-y-4 text-slate-300">
              <ol class="list-decimal list-outside ml-6 space-y-4">
                <li>These Terms and Conditions (hereinafter: "Terms and Conditions"), stipulate the terms and conditions for the provision of Newsletter through the application Kids Tell Tales operating at the Internet address kidstelltales.com (hereinafter: "App").</li>
                <li>The Terms and Conditions constitute the terms and conditions referred to in Article 8 of the Act of 18 July 2002 on Providing Services by Electronic Means (hereinafter: "Electronic Services Act").</li>
                <li>The Provider of the Newsletter is <span class="text-slate-200">KIDS TELL TALES spółka z ograniczoną odpowiedzialnością</span> (limited liability co.) based in Kraków, address: ul. Stelmachów 46b/2, 31-341 Kraków, Poland, entered into entrepreneurs' register administered by the District Court for Kraków- Śródmieście based in Kraków, XI Commercial Division of National Court Register under KRS no. 0001141843, holding Tax ID no. 9452302626, REGON: 54032510300000, with a share capital of 10 050 PLN (hereinafter the "Provider").</li>
                <li>The Provider may be contacted by:
                  <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                    <li>e-mail - at: <a href="mailto:support@kidstelltales.com" class="text-blue-400 hover:text-blue-300">support@kidstelltales.com</a></li>
                    <li>traditional mail - at: Stelmachów 46b/2, 31-341 Kraków, Poland</li>
                    <li>telephone - at: +48 519 481 489</li>
                  </ol>
                </li>
                <li>Before using the App, the user is obliged to read the Terms and Conditions and Privacy Policy.</li>
                <li>To matters not regulated by the present Terms and Conditions, the provisions of the Terms and Conditions of the App, available at: <a href="/terms" class="text-blue-400 hover:text-blue-300">https://kidstelltales.com/terms</a> (hereinafter: "Terms and Conditions").</li>
              </ol>
            </div>
          </section>

          <!-- § 2. Definitions -->
          <section class="mb-12">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
              § 2. Definitions
            </h2>
            <div class="space-y-4 text-slate-300">
              <p>Capitalised words used in these Terms and Conditions shall have the following meanings:</p>
              <ol class="list-decimal list-outside ml-6 space-y-4">
                <li><strong class="text-slate-200">App</strong> - the term defined in § 1 Section 1 hereof;</li>
                <li><strong class="text-slate-200">Consumer</strong> - a natural person making a legal transaction with the Provider which is not directly related to his/her economic or professional activity;</li>
                <li><strong class="text-slate-200">Consumer Rights Act</strong> - the Act of 30 May 2014 on consumer rights;</li>
                <li><strong class="text-slate-200">Electronic Services Act</strong> - the term defined in § 1 Section 2 hereof;</li>
                <li><strong class="text-slate-200">Entrepreneur</strong> - a natural person, a legal person or an organisational unit without legal personality to which specific provisions grant legal capacity, conducting business or professional activity in its own name;</li>
                <li><strong class="text-slate-200">Entrepreneur with Consumer Rights</strong> - a natural person conducting a business or professional activity in his/her own name who has entered into an Agreement with the Provider directly related to his/her business activity, but which is not of a professional nature for this person, arising in particular from the subject of his/her business activity;</li>
                <li><strong class="text-slate-200">Newsletter</strong> - digital content within the meaning of the provisions of the Consumer Rights Act, including commercial information regarding the current activities of the Provider (including information on new products and promotions available in the App);</li>
                <li><strong class="text-slate-200">Newsletter Subscription Agreement</strong> - an agreement for the delivery of Newsletter within the meaning of the Consumer Rights Act under which the Provider undertakes to provide the Subscriber with a Newsletter free of charge for an indefinite period of time, and the Subscriber undertakes to provide the Provider with personal data;</li>
                <li><strong class="text-slate-200">Non-Compliance</strong> - shall be understood as non-compliance of the Newsletter with the Newsletter Subscription Agreement (the criteria for assessing the compliance of the Newsletter with the Newsletter Subscription Agreement are specified in Article 43k (1-2) of the Consumer Rights Act);</li>
                <li><strong class="text-slate-200">Privacy Policy</strong> - a document containing information about the processing of Subscribers' personal data by the Provider;</li>
                <li><strong class="text-slate-200">Provider</strong> - the term defined in § 1 Section 3 hereof;</li>
                <li><strong class="text-slate-200">Terms and Conditions</strong> - the term defined in § 1 Section 1 hereof;</li>
                <li><strong class="text-slate-200">Subscriber</strong> - a person who is a Consumer, Entrepreneur or Entrepreneur with Consumer Rights who has entered into a Newsletter Subscription Agreement with the Provider or has taken steps to enter into such an Agreement.</li>
              </ol>
            </div>
          </section>

          <!-- § 3. Technical requirements -->
          <section class="mb-12">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
              § 3. Technical requirements
            </h2>
            <div class="space-y-4 text-slate-300">
              <ol class="list-decimal list-outside ml-6 space-y-4">
                <li>To receive Newsletter, all of the following requirements must be satisfied jointly:
                  <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                    <li>Internet connection;</li>
                    <li>a device(s) enabling the use of Internet resources;</li>
                    <li>a web browser capable of displaying hypertext documents on the device's screen, linked to the Internet via the World Wide Web service and supporting the JavaScript programming language, which moreover accepts cookies;</li>
                    <li>an active e-mail account.</li>
                  </ol>
                </li>
                <li>Within the App, it is prohibited for Subscribers to use viruses, bots, worms or other computer codes, files or programmes (in particular process automation scripts and applications or other codes, files or tools).</li>
                <li>The Provider informs that it uses cryptographic protection of electronic transfer and Newsletter using appropriate logical, organisational and technical measures, in particular to prevent third-party access to data, including by SSL encryption, use of passwords and anti-virus or anti-malware programmes.</li>
                <li>The Provider advises that despite the application of safeguards referred to in Section 3 above, the use of the Internet and services provided electronically may be at risk of malicious software entering the Subscriber's computer system and device or of third parties gaining access to the data on that device. To minimise the aforementioned risk, the Provider recommends the use of anti-virus programmes or means of protecting identification on the Internet.</li>
              </ol>
            </div>
          </section>

          <!-- § 4. General terms of service -->
          <section class="mb-12">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
              § 4. General terms of service
            </h2>
            <div class="space-y-4 text-slate-300">
              <ol class="list-decimal list-outside ml-6 space-y-4">
                <li>The Subscriber is obliged to use the Newsletter in a manner consistent with generally applicable laws, provisions of the Terms and Conditions, as well as with good morals.</li>
                <li>The provision of unlawful content by the Subscriber is prohibited.</li>
              </ol>
            </div>
          </section>

          <!-- § 5. Newsletter Subscription Agreement -->
          <section class="mb-12">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
              § 5. Newsletter Subscription Agreement
            </h2>
            <div class="space-y-4 text-slate-300">
              <ol class="list-decimal list-outside ml-6 space-y-4">
                <li>To conclude the Newsletter Subscription Agreement, the Subscriber shall provide the Provider with his/her email address and submit a statement that he/she consents to receive the Newsletter, that he/she has read the Terms and Conditions and Privacy Policy and accepts their provisions.</li>
                <li>The actions indicated in Section 1 above may be performed in any way, in particular the Subscriber may fill in the electronic form available in the App.</li>
                <li>The Newsletter Subscription Agreement shall be concluded for an indefinite period.</li>
                <li>The Provider informs and the Subscriber acknowledges that:
                  <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                    <li>the delivered Newsletter is not subject to subsequent update;</li>
                    <li>the Newsletter delivery frequency and dates are not predetermined and depend on the current situation of the Provider.</li>
                  </ol>
                </li>
                <li>The Newsletter shall be delivered by email to the email address provided by the Subscriber.</li>
                <li>The Subscriber may terminate the Newsletter Subscription Agreement with immediate effect at any time and without stating any reason. In addition, pursuant to Article 27 et seq. of the Consumer Rights Act, the Subscriber who is a Consumer or Entrepreneur with Consumer Rights may terminate the Newsletter Subscription Agreement without stating reasons within 14 (fourteen) days from its conclusion.</li>
                <li>Rescission of the Newsletter Subscription Agreement or its termination, regardless of the grounds for such action, shall require a relevant statement to be submitted by the Subscriber to the Provider. The statement referred to in the preceding sentence may be made by:
                  <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                    <li>clicking by the Subscriber on the link enabling cancellation of the Newsletter subscription service, which is sent together with each Newsletter;</li>
                    <li>the Subscriber sending to the Provider a statement on rescission of the Newsletter Subscription Agreement or its termination by email. The statement referred to in this point 2 may also be submitted using the form constituting Appendix No. 2 to the Act on Consumer Rights.</li>
                  </ol>
                </li>
                <li>The Provider shall cease the delivery of the Newsletter to the Subscriber immediately after the Subscriber has performed one of the actions indicated in Section 7 above.</li>
              </ol>
            </div>
          </section>

          <!-- § 6. Complaints concerning Newsletter -->
          <section class="mb-12">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
              § 6. Complaints regarding Newsletter
            </h2>
            <div class="space-y-4 text-slate-300">
              <ol class="list-decimal list-outside ml-6 space-y-4">
                <li>The provisions of this § 6 apply exclusively to Subscribers who are Consumers or Entrepreneurs with Consumer rights;</li>
                <li>The Newsletter supplied by the Provider to the Subscriber must be compliant with the Newsletter Subscription Agreement at the time of delivery.</li>
                <li>The Provider shall be liable for Non-Compliance existing at the time of delivery of the Newsletter and disclosed within 2 (two) years from that time.</li>
                <li>In the event of disclosure of Non-Compliance, the Subscriber may submit a complaint with a demand to bring the Newsletter into compliance with the Newsletter Subscription Agreement.</li>
                <li>The complaint shall be submitted by e-mail to the address indicated in § 1 Section 4 Point 1 hereof.</li>
                <li>The complaint should include:
                  <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                    <li>the name and surname of the Subscriber;</li>
                    <li>e-mail address;</li>
                    <li>a description of the Non-Compliance disclosed;</li>
                    <li>a request to bring the Digital Content into conformity with the Agreement for the provision thereof.</li>
                  </ol>
                </li>
                <li>The Provider may refuse to bring the Newsletter into compliance with the Newsletter Subscription Agreement if this is impossible or would require the Provider to incur excessive costs.</li>
                <li>After considering the complaint, the Provider shall provide the Subscriber with a response to the complaint in which the Provider:
                  <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                    <li>acknowledges the complaint and indicates the planned date for bringing the Newsletter into compliance with the Newsletter Subscription Agreement;</li>
                    <li>refuses to bring the Newsletter into compliance with the Newsletter Subscription Agreement for the reasons indicated in Section 7 above;</li>
                    <li>rejects the complaint on the grounds that it is unsubstantiated.</li>
                  </ol>
                </li>
                <li>The Provider shall respond to the complaint by email within 14 (fourteen) days of receiving the complaint.</li>
                <li>If the complaint is accepted, the Provider shall, at its own expense, bring the Newsletter into conformity with the Newsletter Subscription Agreement within a reasonable time from the date of receiving the complaint and without undue inconvenience to the Subscriber, taking into account the nature of the Newsletter and the purpose for which it is used. The planned deadline for bringing the Newsletter into compliance with the Newsletter Subscription Agreement shall be indicated by the Provider in its response to the complaint.</li>
                <li>If a Non-Compliance is disclosed, the Subscriber may submit to the Provider a statement on rescission of the Agreement when:
                  <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                    <li>it is either impossible to bring the Newsletter into conformity with the Newsletter Subscription Agreement or excessive costs would have to be incurred;</li>
                    <li>the Provider has failed to bring the Newsletter into conformity with the Newsletter Subscription Agreement in accordance with Section 10 above;</li>
                    <li>the Non-Compliance continues even though the Provider has attempted to bring Newsletter into compliance with Newsletter Subscription Agreement;</li>
                    <li>the Non-Compliance is so significant as to justify rescission of the Newsletter Subscription Agreement without first requiring the Provider to bring the Newsletter into compliance with the Newsletter Subscription Agreement;</li>
                    <li>it is clear from the Provider's statement or circumstances that the Provider will not bring the Newsletter into conformity with the Newsletter Subscription Agreement within a reasonable time or without undue inconvenience to the Subscriber.</li>
                  </ol>
                </li>
                <li>The statement on rescission of the Agreement may be made by e-mail to the address indicated in § 1 Section 4 Point 1 hereof.</li>
                <li>The statement on rescission of the Agreement should include:
                  <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                    <li>the Subscriber's name and surname;</li>
                    <li>e-mail address;</li>
                    <li>date of delivery of the Newsletter;</li>
                    <li>description of the Non-Compliance disclosed;</li>
                    <li>indication of the reason for the statement, selected from the reasons indicated in Section 11 above;</li>
                    <li>a statement of rescission of the Agreement.</li>
                  </ol>
                </li>
                <li>The Subscriber is not entitled to rescind the Newsletter Subscription Agreement if the Non-Compliance is immaterial.</li>
                <li>If the Subscriber rescinds the Newsletter Subscription Agreement, the Provider shall cease to provide the Newsletter immediately upon receipt of the statement on rescission of the Newsletter Subscription Agreement.</li>
              </ol>
            </div>
          </section>

          <!-- § 7. Personal data processing -->
          <section class="mb-12">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
              § 7. Personal data processing
            </h2>
            <p class="text-slate-300">For information about the Provider's processing of personal data, please refer to the Privacy Policy available at: <a href="/privacy" class="text-blue-400 hover:text-blue-300">https://kidstelltales.com/privacy</a></p>
          </section>

          <!-- § 8. Out-of-court dispute resolution -->
          <section class="mb-12">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
              § 8. Out-of-court dispute resolution
            </h2>
            <div class="space-y-4 text-slate-300">
              <ol class="list-decimal list-outside ml-6 space-y-4">
                <li>The provisions of this § 8 apply only to Subscribers who are Consumers.</li>
                <li>The Subscriber may use the out-of-court ways of dealing with complaints and pursuing claims.</li>
                <li>Detailed information on the possibility of using out-of-court complaint and redress procedures by the Subscriber, as well as rules of access to these procedures, are available at the offices and websites of:
                  <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                    <li>poviat (municipal) consumer ombudsmen, social organisations whose statutory tasks include consumer protection;</li>
                    <li>Voivodship Inspectorates of Trade Inspection;</li>
                    <li>Office for Competition and Consumer Protection.</li>
                  </ol>
                </li>
                <li>The Subscriber may also use the platform for online dispute resolution (ODR) available at: http://ec.europa.eu/consumers/odr/.</li>
              </ol>
            </div>
          </section>

          <!-- § 9. Amendments to the Terms and Conditions -->
          <section class="mb-12">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
              § 9. Amendments to the Terms and Conditions
            </h2>
            <div class="space-y-4 text-slate-300">
              <ol class="list-decimal list-outside ml-6 space-y-4">
                <li>The Provider may amend the Terms and Conditions in the event:
                  <ol class="list-decimal list-outside ml-6 mt-2 space-y-2">
                    <li>of a change in the Provider's data;</li>
                    <li>of a change of the objects of the Provider's business;</li>
                    <li>the Provider starts to provide new services, modifies the services previously provided or ceases to provide them;</li>
                    <li>of technical modifications of the Newsletter requiring an adjustment of the provisions of the Terms and Conditions to reflect them;</li>
                    <li>of a legal obligation to make changes, including the obligation to adapt the Terms and Conditions to the current state of the law.</li>
                  </ol>
                </li>
                <li>Subscribers will be informed of the amendments to the Terms and Conditions by publication of the amended version on the App's website. At the same time, the amended version of the Terms and Conditions will be sent to Subscribers by e-mail.</li>
                <li>The Subscriber who does not agree to the amendment to the Terms and Conditions may terminate the Newsletter Subscription Agreement with immediate effect within 7 (seven) days of receiving the amended version of the Terms and Conditions by e-mail. Failure to terminate shall be deemed acceptance of the amended Terms and Conditions.</li>
                <li>Termination of the Newsletter Subscription Agreement shall be effected by submitting to the Provider a statement on termination of the Newsletter Subscription Agreement by the Subscriber. The statement referred to in the preceding sentence may be sent by email to the address indicated in § 1 Section 4 Point 1 of the Terms and Conditions.</li>
                <li>Immediately upon receipt of the statement referred to in Section 4 above, the Provider shall cease Newsletter delivery.</li>
              </ol>
            </div>
          </section>

          <!-- § 10. Final provisions -->
          <section class="mb-12">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
              § 10. Final provisions
            </h2>
            <div class="space-y-4 text-slate-300">
              <ol class="list-decimal list-outside ml-6 space-y-4">
                <li>These Terms and Conditions and the Newsletter Subscription Agreement shall be governed by Polish law. However, the choice of Polish law made in the preceding sentence shall not deprive the Consumer of the protection stemming from the provisions of foreign law which cannot be excluded by agreement, and which would be applicable in the absence of the choice of Polish law made in the preceding sentence.</li>
                <li>The current version of the Terms and Conditions is effective as of 18.12.2024</li>
              </ol>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useMetaTags } from "@/components/useMetaTags"

const route = useRoute()

onMounted(() => {
  useMetaTags({
    title: 'Newsletter Terms and Conditions',
    path: route.path,
  })
})
</script>