<template>
  <div>
    <LoadingSkeleton v-if="!sessionStore.currentSession && sessionStore.loading" text="Loading your story"/>
    <div v-else>
      <div v-if="sessionStore.currentSession">
        <div>
          <div class="p-6 max-w-3xl mx-auto">
            <!-- Add this right after LoadingSkeleton in the template -->
            <div v-if="sessionStore.currentSession" class="space-y-1">
              <button
                  @click="showDetails = !showDetails"
                  class="w-full flex items-start justify-between to-transparent group"
              >
                <h1 class="text-3xl md:text-4xl font-display font-bold text-slate-100">
                  {{ sessionStore.currentSession.title }}
                </h1>
                <ChevronDown
                    class="h-6 w-6 text-slate-400 group-hover:text-slate-300 transition-transform duration-200 flex-shrink-0 mt-2"
                    :class="{ 'rotate-180': showDetails }"
                />
              </button>

              <!-- Story Details -->
              <Transition
                  enter-active-class="transition-all duration-200 ease-out"
                  enter-from-class="opacity-0 -translate-y-2"
                  enter-to-class="opacity-100 translate-y-0"
                  leave-active-class="transition-all duration-200 ease-in"
                  leave-from-class="opacity-100 translate-y-0"
                  leave-to-class="opacity-0 -translate-y-2"
              >
                <div v-if="showDetails" class="py-4 space-y-4">
                  <!-- Story Details Grid -->
                  <div class="space-y-2">
                    <div class="text-sm text-slate-400">Prompt</div>
                    <div class="p-4 bg-slate-800/50 rounded-lg border border-slate-700/50">
                      <p class="text-slate-200">{{ sessionStore.currentSession.session_params.initial_prompt }}</p>
                    </div>
                  </div>

                  <div class="grid grid-cols-3 gap-4">
                    <!-- Age Band -->
                    <div class="p-4 bg-slate-800/50 rounded-lg border border-slate-700/50">
                      <div class="text-center">
                        <div class="text-2xl mb-1">{{ ageEmoji }}</div>
                        <div class="text-sm text-slate-400">Age Band</div>
                        <div class="text-slate-200 font-medium">{{
                            sessionStore.currentSession.session_params.age_band
                          }}
                        </div>
                      </div>
                    </div>

                    <!-- Story Length -->
                    <div class="p-4 bg-slate-800/50 rounded-lg border border-slate-700/50">
                      <div class="text-center">
                        <div class="text-2xl mb-1">{{ lengthEmoji }}</div>
                        <div class="text-sm text-slate-400">Length</div>
                        <div class="text-slate-200 font-medium">{{ getLengthLabel }}</div>
                      </div>
                    </div>

                    <!-- Progress -->
                    <div class="p-4 bg-slate-800/50 rounded-lg border border-slate-700/50">
                      <div class="text-center">
                        <div class="text-2xl mb-1">📝</div>
                        <div class="text-sm text-slate-400">Progress</div>
                        <div class="text-slate-200 font-medium">{{ progressLabel }}</div>
                      </div>
                    </div>
                  </div>

                  <!-- Prompt -->

                </div>
              </Transition>
            </div>

            <div v-if="!sessionStore.currentUser" class="max-w-3xl mx-auto pt-6">
              <div class="p-6 rounded-xl bg-gradient-to-r from-blue-600/10 via-indigo-600/10 to-blue-600/10 border border-blue-500/20">
                <div class="space-y-4">
                  <h3 class="text-xl font-display font-semibold text-blue-400 text-center">Welcome to Kids Tell Tales!</h3>
                  <div class="space-y-2">
                    <p class="text-slate-300">
                      You're reading a story created by one of our users. ✨
                    </p>
                    <p class="text-slate-300">
                      Want your own story about anything you can imagine? A pizza-loving dinosaur?
                      Your teddy bear becoming an astronaut? Just type it in and get your story instantly!
                    </p>
                  </div>
                  <div class="flex justify-center mt-6">
                    <button
                        @click="redirectToLogin"
                        class="group inline-flex items-center justify-center px-8 py-3
                   bg-blue-600 hover:bg-blue-500 text-white rounded-lg font-semibold
                   transition-all duration-200">
                      <Sparkles class="h-5 w-5 mr-2 group-hover:scale-110 transition-transform"/>
                      <span class="text-center">Try it free!</span>
                      <ArrowRight class="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="sessionStore.currentSession.under_copywright && belongsToCurrentUser"
                 class="mt-4 p-4 bg-yellow-600/10 border border-yellow-500/20 rounded-lg">
              <div class="flex items-start space-x-3">
                <AlertTriangleIcon class="h-5 w-5 text-yellow-300 flex-shrink-0 mt-0.5"/>
                <p class="text-yellow-300 text-sm">
                  {{ sessionStore.currentSession.under_copywright_explanation }}
                </p>
              </div>
            </div>

            <!-- Visibility Toggle -->
            <div v-if="belongsToCurrentUser && isFinished" class="mt-4 flex items-center justify-between">
              <div class="flex items-center space-x-2">
                <Globe v-if="sessionStore.currentSession.is_public" class="h-5 w-5 text-blue-400"/>
                <Lock v-else class="h-5 w-5 text-slate-400"/>
                <span class="text-slate-300">{{
                    sessionStore.currentSession.is_public ? 'Public Story' : 'Private Story'
                  }}</span>
              </div>
              <label class="relative inline-flex items-center cursor-pointer">
                <input
                    type="checkbox"
                    :checked="sessionStore.currentSession.is_public"
                    class="sr-only peer"
                    @change="toggleVisibility"
                >
                <div class="w-14 h-7 bg-slate-700 rounded-full peer
                        peer-checked:bg-blue-600
                        peer-focus:ring-4 peer-focus:ring-blue-600/20
                        after:content-[''] after:absolute after:top-0.5 after:left-0.5
                        after:bg-white after:rounded-full after:h-6 after:w-6
                        after:transition-all peer-checked:after:translate-x-7">
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- Story Content -->
      <div class="max-w-3xl mx-auto p-6">
        <div v-if="sessionStore.currentSession" class="space-y-8">
          <!-- Previous Messages -->
          <div v-for="message in sessionStore.currentSession.messages.slice(0, -1)"
               :key="message.timestamp"
               class="space-y-6 animate-fade-in">
            <div v-if="message.text"
                 class="text-lg text-slate-200 leading-relaxed whitespace-pre-line">
              {{ message.text }}
            </div>

            <!-- Previous Choices -->
            <div v-if="message.question" class="space-y-3">
              <div v-for="option in message.question.options"
                   :key="option"
                   :class="[
               'px-6 py-4 rounded-lg transition-all duration-200',
               option === message.question.answer
                 ? 'bg-blue-600/10 text-blue-400 border border-blue-500/20'
                 : 'bg-slate-800/30 text-slate-500'
             ]"
              >
                {{ option }}
              </div>
            </div>
          </div>

          <!-- Latest Content Group -->
          <div v-if="sessionStore.currentSession.messages.length > 0" class="space-y-8" ref="lastMessage">
            <!-- Latest Message -->
            <div class="space-y-6 animate-fade-in">
              <div v-if="sessionStore.currentSession.messages[sessionStore.currentSession.messages.length - 1].text"
                   class="text-lg text-slate-200 leading-relaxed whitespace-pre-line">
                {{ sessionStore.currentSession.messages[sessionStore.currentSession.messages.length - 1].text }}
              </div>
            </div>

            <!-- Current Choices -->
            <div v-if="!isFinished" class="space-y-3">
              <div v-for="option in sessionStore.currentSession.current_question"
                   :key="option"
                   @click="!sessionStore.loadingNewAnswer && subscriptionInfo.hasActiveSubscription && answerQuestion(option)"
                   :class="[
               'px-6 py-4 rounded-lg transition-all duration-200',
               subscriptionInfo.hasActiveSubscription && !sessionStore.loadingNewAnswer
                 ? 'cursor-pointer bg-slate-800/50 border border-slate-600/50 hover:bg-blue-600/10 hover:border-blue-500/20 hover:text-blue-400'
                 : 'opacity-50 cursor-not-allowed bg-slate-800/30 border border-slate-700/50'
             ]"
              >
                {{ option }}
              </div>
            </div>

            <!-- Story End Card -->
            <!-- Story End Card -->
            <div v-if="isFinished"
                 class="bg-slate-800/50 backdrop-blur border border-slate-700/50 rounded-xl overflow-hidden">
              <div class="p-6 space-y-8">
                <!-- For story owner -->
                <template v-if="belongsToCurrentUser">
                  <!-- Story Rating -->
                  <div class="space-y-4">
                    <h2 class="text-2xl font-display font-semibold text-slate-100">How did you like the story?</h2>
                    <div class="flex justify-center space-x-12">
                      <button v-for="rating in ratings"
                              :key="rating.value"
                              @click="selectFeedback(rating.value)"
                              class="group transition-all duration-300 ease-in-out transform hover:scale-110">
                        <component
                            :is="rating.icon"
                            class="h-8 w-8 transition-opacity"
                            :class="{ 'opacity-30 group-hover:opacity-50': sessionStore.currentSession.session_feedback.value !== rating.value }"
                            :stroke="sessionStore.currentSession.session_feedback.value === rating.value ? '#60A5FA' : 'currentColor'"
                        />
                      </button>
                    </div>
                  </div>

                  <!-- Share Button -->
                  <button
                      @click="openShareOptions"
                      class="w-full px-6 py-4 bg-blue-600 hover:bg-blue-500
               text-white rounded-lg text-lg font-semibold
               transition-all duration-200
               flex items-center justify-center space-x-2">
                    <Share2 class="h-5 w-5"/>
                    <span>Share Your Story</span>
                  </button>
                </template>

                <!-- For non-logged-in readers -->
                <!-- For non-logged-in readers -->
                <template v-else-if="!sessionStore.currentUser">
                  <div class="text-center space-y-6 max-w-xl mx-auto">
                    <!-- Title section with improved spacing -->
                    <div class="space-y-6">
                      <div class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-gradient-to-r from-blue-600/20 to-indigo-600/20 border border-blue-500/20">
                        <Sparkles class="h-8 w-8 text-blue-400"/>
                      </div>

                      <div class="space-y-3">
                        <h2 class="text-3xl font-display font-semibold text-slate-100">
                          Ready to create your own stories?
                        </h2>
                      </div>
                    </div>

                    <!-- How it works section with improved spacing and styling -->
                    <div class="space-y-2">
                      <div class="space-y-2">
                        <!-- Step 1 -->
                        <div class="flex flex-col md:flex-row items-center gap-6 p-4 ">
                          <div class="text-center md:text-left">
                            <h4 class="text-lg font-display font-medium text-slate-100 mb-1">
                              <span class="mr-2">👋</span>Register
                            </h4>
                            <p class="text-slate-400">Create your free account to start your journey</p>
                          </div>
                        </div>

                        <!-- Step 2 -->
                        <div class="flex flex-col md:flex-row items-center gap-6 p-4">
                          <div class="text-center md:text-left">
                            <h4 class="text-lg font-display font-medium text-slate-100 mb-1">
                              <span class="mr-2">✨</span>Type Any Topic
                            </h4>
                            <p class="text-slate-400">From magical dragons to space adventures - you decide!</p>
                          </div>
                        </div>

                        <!-- Step 3 -->
                        <div class="flex flex-col md:flex-row items-center gap-6 p-4 rounded-xl">
                          <div class="text-center md:text-left">
                            <h4 class="text-lg font-display font-medium text-slate-100 mb-1">
                              <span class="mr-2">📖</span>Get Your Story
                            </h4>
                            <p class="text-slate-400">Watch your story come to life instantly</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Action buttons with improved spacing -->
                    <div class="flex flex-col gap-4 pt-4">
                      <button
                          @click="redirectToLogin"
                          class="w-full px-6 py-4 bg-gradient-to-r from-blue-600 to-indigo-600
                 hover:from-blue-500 hover:to-indigo-500
                 text-white rounded-xl font-semibold
                 transition-all duration-200 group
                 shadow-lg hover:shadow-blue-500/25"
                      >
                        <Star class="inline-block h-5 w-5 mr-2 group-hover:scale-110 transition-transform"/>
                        <span>Create your first stories for free!</span>
                      </button>

                      <button
                          @click="redirectToHomepage"
                          class="w-full px-6 py-4 bg-slate-800/50
                 border border-slate-700/50
                 hover:bg-slate-700/50 hover:border-slate-600/50
                 text-slate-300 hover:text-white rounded-xl
                 font-semibold transition-all duration-200 group"
                      >
                        <BookOpen class="inline-block h-5 w-5 mr-2 group-hover:scale-110 transition-transform"/>
                        <span>Learn more</span>
                      </button>
                    </div>
                  </div>
                </template>
                <template v-else-if="!subscriptionInfo.hasActiveSubscription">
                  <div class="text-center space-y-6">
                    <div class="flex justify-center">
                      <div
                          class="h-16 w-16 bg-blue-600/10 rounded-full flex items-center justify-center border border-blue-500/20">
                        <Sparkles class="h-8 w-8 text-blue-400"/>
                      </div>
                    </div>
                    <div>
                      <h2 class="text-2xl font-display font-semibold text-slate-100 mb-2">
                        Ready to create your own stories?
                      </h2>
                      <p class="text-slate-300">
                        Purchase now to begin your storytelling adventure!
                      </p>
                    </div>
                    <div class="flex flex-col sm:flex-row gap-4 justify-center">
                      <button
                          @click="redirectToPurchase"
                          class="inline-flex items-center justify-center px-6 py-3
                   bg-blue-600 hover:bg-blue-500 text-white rounded-lg
                   font-semibold transition-all duration-200 group">
                        <Star class="h-5 w-5 mr-2 group-hover:scale-110 transition-transform"/>
                        <span>Start for just $7.99!</span>
                      </button>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="text-center space-y-6">
                    <div class="flex justify-center">
                      <div
                          class="h-16 w-16 bg-blue-600/10 rounded-full flex items-center justify-center border border-blue-500/20">
                        <Sparkles class="h-8 w-8 text-blue-400"/>
                      </div>
                    </div>
                    <div>
                      <h2 class="text-2xl font-display font-semibold text-slate-100 mb-2">
                        Ready to create your own stories?
                      </h2>
                    </div>
                    <div class="flex flex-col sm:flex-row gap-4 justify-center">
                      <button
                          @click="redirectToHistory"
                          class="w-full px-6 py-4 bg-blue-600 hover:bg-blue-500
              text-white rounded-lg text-lg font-semibold
              transition-all duration-200
              flex items-center justify-center space-x-3
              group"
                      >
                        <BookOpen class="h-5 w-5 group-hover:scale-110 transition-transform"/>
                        <span>Return to Stories</span>
                      </button>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <!-- Subscription Banner -->
          <SubscriptionBanner v-if="belongsToCurrentUser && !isFinished"/>

          <!-- Loading State -->
          <div v-if="sessionStore.loadingNewAnswer"
               ref="thinking"
               class="flex items-center justify-center space-x-2 text-blue-400">
            <span>{{ loadingText }}</span>
            <LoadingDots/>
          </div>

          <div ref="error">
            <ErrorBox/>
          </div>
        </div>
      </div>


      <!-- Share Dialog - Desktop Modal / Mobile Bottom Sheet -->
      <Transition name="fade">
        <div v-if="showShareOptions"
             class="fixed inset-0 z-50">
          <!-- Backdrop -->
          <div class="absolute inset-0 bg-slate-900/90 backdrop-blur-sm"
               @click="closeShareOptions"></div>

          <!-- Desktop Modal -->
          <div class="hidden md:flex justify-center fixed inset-x-0 bottom-0 mb-24 p-6">
            <div class="w-full max-w-md bg-slate-800/95 backdrop-blur-sm border border-slate-700/50 rounded-xl"
                 @click.stop>
              <div class="p-6 space-y-6">
                <div class="flex justify-between items-center">
                  <h2 class="text-xl font-display font-semibold text-slate-100">Share Your Story</h2>
                  <button @click="closeShareOptions"
                          class="text-slate-400 hover:text-slate-300 transition-colors">
                    <X class="h-5 w-5"/>
                  </button>
                </div>

                <div v-if="!sessionStore.currentSession?.is_public"
                     class="p-4 bg-yellow-600/10 border border-yellow-500/20 rounded-lg">
                  <div class="flex items-start space-x-3">
                    <AlertTriangle class="h-5 w-5 text-yellow-300 flex-shrink-0 mt-0.5"/>
                    <p class="text-yellow-300 text-sm">
                      Sharing will make this story public and visible to anyone with the link.
                    </p>
                  </div>
                </div>

                <div class="space-y-3">
                  <button @click="initiateShare('facebook')"
                          class="w-full p-4 rounded-lg bg-blue-600/10 border border-blue-500/20
                               text-blue-400 hover:bg-blue-600/20
                               transition-all duration-200
                               flex items-center justify-center space-x-2">
                    <Facebook class="h-5 w-5"/>
                    <span>Share on Facebook</span>
                  </button>
                  <button @click="initiateShare('twitter')"
                          class="w-full p-4 rounded-lg bg-slate-800 border border-slate-700/50
                               text-slate-300 hover:bg-slate-700 hover:text-white
                               transition-all duration-200
                               flex items-center justify-center space-x-2">
                    <Twitter class="h-5 w-5"/>
                    <span>Share on X</span>
                  </button>
                  <button @click="initiateShare('copy')"
                          class="w-full p-4 rounded-lg bg-slate-800 border border-slate-700/50
                               text-slate-300 hover:bg-slate-700 hover:text-white
                               transition-all duration-200
                               flex items-center justify-center space-x-2">
                    <ClipboardCopy class="h-5 w-5"/>
                    <span>Copy Link</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Mobile Bottom Sheet -->
          <Transition name="slide-up">
            <div class="md:hidden absolute inset-x-0 bottom-0">
              <div class="bg-slate-800/95 backdrop-blur-sm border-t border-slate-700/50 rounded-t-xl"
                   @click.stop>
                <!-- Drag Handle -->
                <div class="flex justify-center p-2">
                  <div class="w-12 h-1.5 rounded-full bg-slate-600"></div>
                </div>

                <div class="p-6 space-y-6">
                  <div class="flex justify-between items-center">
                    <h2 class="text-xl font-display font-semibold text-slate-100">Share Your Story</h2>
                    <button @click="closeShareOptions"
                            class="text-slate-400 hover:text-slate-300 transition-colors">
                      <X class="h-5 w-5"/>
                    </button>
                  </div>

                  <div v-if="!sessionStore.currentSession?.is_public"
                       class="p-4 bg-yellow-600/10 border border-yellow-500/20 rounded-lg">
                    <div class="flex items-start space-x-3">
                      <AlertTriangle class="h-5 w-5 text-yellow-300 flex-shrink-0 mt-0.5"/>
                      <p class="text-yellow-300 text-sm">
                        Sharing will make this story public and visible to anyone with the link.
                      </p>
                    </div>
                  </div>

                  <div class="space-y-3">
                    <button @click="initiateShare('facebook')"
                            class="w-full p-4 rounded-lg bg-blue-600/10 border border-blue-500/20
                                 text-blue-400 hover:bg-blue-600/20
                                 transition-all duration-200
                                 flex items-center justify-center space-x-2">
                      <Facebook class="h-5 w-5"/>
                      <span>Share on Facebook</span>
                    </button>
                    <button @click="initiateShare('twitter')"
                            class="w-full p-4 rounded-lg bg-slate-800 border border-slate-700/50
                                 text-slate-300 hover:bg-slate-700 hover:text-white
                                 transition-all duration-200
                                 flex items-center justify-center space-x-2">
                      <Twitter class="h-5 w-5"/>
                      <span>Share on X</span>
                    </button>
                    <button @click="initiateShare('copy')"
                            class="w-full p-4 rounded-lg bg-slate-800 border border-slate-700/50
                                 text-slate-300 hover:bg-slate-700 hover:text-white
                                 transition-all duration-200
                                 flex items-center justify-center space-x-2">
                      <ClipboardCopy class="h-5 w-5"/>
                      <span>Copy Link</span>
                    </button>
                  </div>

                  <!-- Extra padding for mobile devices -->
                  <div class="h-6"></div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </Transition>

      <!-- Copy Toast -->
      <Transition name="fade">
        <div v-if="showToast"
             class="fixed bottom-4 right-4 px-4 py-2 bg-emerald-600 text-white rounded-lg shadow-lg">
          Link copied to clipboard!
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, nextTick, onBeforeUnmount, onMounted, ref, watch} from 'vue'
import {useRoute, useRouter} from "vue-router"
import {getCurrentUser} from "vuefire"
import {useSessionStore} from '@/store/session'
import LoadingDots from '@/components/LoadingDots.vue'
import ErrorBox from "@/components/ErrorBox.vue"
import SubscriptionBanner from "@/components/SubscriptionBanner.vue"
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
// Add these imports to the existing imports
import {
  AlertTriangle,
  AlertTriangleIcon,
  BookOpen,
  ChevronDown,
  ClipboardCopy,
  Facebook,
  Globe,
  Heart,
  Lock,
  Share2,
  Sparkles,
  Star,
  ThumbsDown,
  ThumbsUp,
  Twitter,
    ArrowRight,
  X
} from 'lucide-vue-next'
import { UserPlus, PencilLine } from 'lucide-vue-next'
import {useMetaTags} from "@/components/useMetaTags";
import {useSubscription} from "@/composables/useSubscription";
import {scrollToElement} from '@/utils/scroll'

const route = useRoute()
const router = useRouter()
const {subscriptionInfo} = useSubscription()
const sessionStore = useSessionStore()
const thinking = ref<HTMLElement | null>(null)
const error = ref<HTMLElement | null>(null)
const lastMessage = ref<HTMLElement | null>(null)
const showShareOptions = ref(false)
const showToast = ref(false)
const loadingText = ref('Thinking')
let loadingTimeout: ReturnType<typeof setTimeout> | null = null

// Add these to the script setup section
const showDetails = ref(false)

// Helper computed properties
const ageEmoji = computed(() => {
  const ageBand = sessionStore.currentSession?.session_params.age_band
  switch (ageBand) {
    case '3-4':
      return '👶'
    case '5-7':
      return '🧒'
    case '8+':
      return '🧑'
    default:
      return '👤'
  }
})

const lengthEmoji = computed(() => {
  const length = sessionStore.currentSession?.session_params.length
  switch (length) {
    case 4:
      return '🐁'
    case 6:
      return '🐈'
    case 8:
      return '🐘'
    default:
      return '📏'
  }
})

const getLengthLabel = computed(() => {
  const length = sessionStore.currentSession?.session_params.length
  switch (length) {
    case 4:
      return 'Short'
    case 6:
      return 'Medium'
    case 8:
      return 'Long'
    default:
      return length
  }
})

const progressLabel = computed(() => {
  if (!sessionStore.currentSession) return ''
  const totalLength = sessionStore.currentSession.session_params.length + 1
  const currentProgress = 1 + Math.floor(sessionStore.currentSession.messages.length / 2)
  return `${currentProgress}/${totalLength}`
})

const ratings = [
  {value: -1, icon: ThumbsDown},
  {value: 1, icon: ThumbsUp},
  {value: 2, icon: Heart}
]

const belongsToCurrentUser = computed(() => {
  return sessionStore.currentUser && sessionStore.currentUser.id === sessionStore.currentSession?.user_id
})

const isFinished = computed(() => {
  return !sessionStore.currentSession?.current_question || sessionStore.currentSession?.current_question?.length === 0
})

onMounted(async () => {
  const user = await getCurrentUser()
  if (user) {
    await sessionStore.fetchUser()
  }

  if (route.params.sessionId) {
    await sessionStore.fetchSession(route.params.sessionId as string)

    if (sessionStore.currentSession?.title) {
      useMetaTags({
        title: sessionStore.currentSession.title,
        path: route.path,
        description: sessionStore.currentSession.messages[0].text
      })
    }

  }
})

const answerQuestion = async (option: string) => {
  sessionStore.loadingNewAnswer = true
  await nextTick()

  scrollToElement(thinking.value!)
  await sessionStore.answerQuestion(option)
  await nextTick()

  if (sessionStore.error) {
    scrollToElement(error.value!)
  } else {
    scrollToElement(lastMessage.value!)
  }
}

watch(() => sessionStore.loadingNewAnswer, (isLoading) => {
  if (isLoading) {
    loadingText.value = 'Thinking'
    loadingTimeout = setTimeout(() => {
      loadingText.value = 'Still on it'
    }, 5000)
  } else {
    if (loadingTimeout) {
      clearTimeout(loadingTimeout)
      loadingTimeout = null
    }
  }
})

// Clean up the timeout if the component is unmounted
onBeforeUnmount(() => {
  if (loadingTimeout) {
    clearTimeout(loadingTimeout)
  }
})

const selectFeedback = (rating: number) => {
  sessionStore.submitFeedback({value: rating})
}

const toggleVisibility = async () => {
  await sessionStore.toggleVisibility()
}

const openShareOptions = () => {
  showShareOptions.value = true
}

const closeShareOptions = () => {
  showShareOptions.value = false
}

const getShareUrl = () => {
  return `https://kidstelltales.com/sessions/${sessionStore.currentSession?.id}`
}

const initiateShare = async (platform: string) => {
  if (!sessionStore.currentSession?.is_public) {
    await sessionStore.toggleVisibility()
  }

  switch (platform) {
    case 'facebook':
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(getShareUrl())}`, '_blank')
      break
    case 'twitter': {
      const text = encodeURIComponent(`Check out my interactive story: ${sessionStore.currentSession?.title}`)
      window.open(`https://twitter.com/intent/tweet?text=${text}&url=${encodeURIComponent(getShareUrl())}`, '_blank')
      break
    }
    case 'copy':
      try {
        await navigator.clipboard.writeText(getShareUrl())
        showToast.value = true
        setTimeout(() => {
          showToast.value = false
        }, 3000)
      } catch (err) {
        console.error('Failed to copy: ', err)
      }
      break
  }

  closeShareOptions()
}

const redirectToLogin = () => {
  router.push('/login')
}
const redirectToPurchase = () => {
  router.push('/purchase')
}
const redirectToHistory = () => {
  router.push('/history')
}
const redirectToHomepage = () => {
  router.push('/')
}
</script>

<style scoped>
* {
  overflow-anchor: none !important;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.3s ease-out;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fadeIn 0.5s ease-out forwards;
}
</style>