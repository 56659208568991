<template>
  <LoadingSkeleton v-if="!sessionStore.currentUser && sessionStore.loading" text="Loading"/>
  <div v-else>
    <!-- Header -->
    <div class="p-6 bg-gradient-to-b from-slate-800/50 to-transparent">
      <h1 class="text-3xl md:text-4xl font-display font-bold text-slate-100">
        Create a New Story
      </h1>
    </div>

    <div v-if="sessionStore.currentUser" class="px-6 pb-6">
      <SubscriptionBanner/>

      <form @submit.prevent="createNewSession" class="max-w-2xl space-y-8">
        <div class="space-y-3">
          <!-- Label area with inspiration button -->
          <div class="flex items-center justify-between">
            <label for="initialPrompt" class="text-lg font-medium text-slate-200">
              What's your story about?
            </label>
            <button
                type="button"
                @click="getNewInspiration"
                :disabled="!subscriptionInfo.hasActiveSubscription"
                class="text-sm px-3 py-1.5 rounded-lg
             bg-slate-800/50 border border-slate-600/50
             text-slate-300 hover:text-blue-400
             hover:border-blue-500/30 hover:bg-blue-600/10
             disabled:opacity-50 disabled:cursor-not-allowed
             transition-all duration-200
             flex items-center space-x-2"
            >
              <template v-if="!currentInspiration">
                <Sparkles class="h-4 w-4"/>
                <span>Need inspiration?</span>
              </template>
              <template v-else>
                <RefreshCw class="h-4 w-4"/>
                <span>Try another idea</span>
              </template>
            </button>
          </div>

          <!-- Textarea with subtle character count -->
          <div class="relative">
    <textarea
        id="initialPrompt"
        v-model="initialPrompt"
        required
        :disabled="!subscriptionInfo.hasActiveSubscription"
        rows="2"
        class="w-full px-4 py-3 rounded-lg bg-slate-800/50 border border-slate-600/50
             text-slate-100 placeholder-slate-500
             focus:border-blue-500/50 focus:ring-2 focus:ring-blue-500/20
             disabled:opacity-50 disabled:cursor-not-allowed
             transition-all duration-200 resize-none"
        placeholder="Enter your story idea..."
        maxlength="100"
    />
            <span class="absolute bottom-2 right-3 text-sm text-slate-500">
      {{ initialPrompt.length }}/100
    </span>
          </div>
        </div>

        <!-- Age Band Selection -->
        <div class="space-y-3">
          <label class="block text-lg font-medium text-slate-200">Who is this story for?</label>
          <div class="grid grid-cols-3 gap-3">
            <button
                v-for="age in ['3-4', '5-7', '8+']"
                :key="age"
                type="button"
                @click="ageBand = age"
                :disabled="!subscriptionInfo.hasActiveSubscription"
                class="relative p-4 rounded-lg transition-all duration-200 group"
                :class="[
                ageBand === age
                  ? 'bg-blue-600/20 border-blue-500/50'
                  : 'bg-slate-800/50 border-slate-600/50 hover:border-blue-500/30',
                'border disabled:opacity-50 disabled:cursor-not-allowed'
              ]"
            >
              <div class="flex flex-col items-center">
                <span class="text-3xl mb-2 transition-transform duration-200 group-hover:scale-110">{{
                    ageEmoji(age)
                  }}</span>
                <span class="text-sm font-medium" :class="ageBand === age ? 'text-blue-400' : 'text-slate-300'">{{
                    age
                  }} years</span>
              </div>
            </button>
          </div>
        </div>

        <!-- Story Length Selection -->
        <div class="space-y-3">
          <label class="block text-lg font-medium text-slate-200">How long should the story be?</label>
          <div class="grid grid-cols-3 gap-3">
            <button
                v-for="(label, value) in {'4': 'Short', '6': 'Medium', '8': 'Long'}"
                :key="value"
                type="button"
                @click="length = value"
                :disabled="!subscriptionInfo.hasActiveSubscription"
                class="relative p-4 rounded-lg transition-all duration-200 group"
                :class="[
                length === value
                  ? 'bg-blue-600/20 border-blue-500/50'
                  : 'bg-slate-800/50 border-slate-600/50 hover:border-blue-500/30',
                'border disabled:opacity-50 disabled:cursor-not-allowed'
              ]"
            >
              <div class="flex flex-col items-center">
                <span class="text-3xl mb-2 transition-transform duration-200 group-hover:scale-110">{{
                    lengthEmoji(value)
                  }}</span>
                <span class="text-sm font-medium"
                      :class="length === value ? 'text-blue-400' : 'text-slate-300'">{{ label }}</span>
              </div>
            </button>
          </div>
        </div>

        <!-- Error Message -->
        <div
            v-if="promptNotSuitable && !sessionStore.loading"
            class="p-6 bg-red-600/10 border border-red-500/20 rounded-lg"
        >
          <div class="flex items-start space-x-3">
            <span class="text-2xl mt-1">⚠️</span>
            <div>
              <h2 class="text-lg font-semibold text-red-400 mb-1">Content Not Suitable for Children</h2>
              <p class="text-red-300">{{ notSuitableExplanation }}</p>
            </div>
          </div>
        </div>

        <!-- Terms and Conditions Section -->
        <div v-if="!sessionStore.currentUser?.active_period?.consent_given" class="space-y-4">
          <!-- Terms and Conditions Checkbox -->
          <label class="flex items-start space-x-3 cursor-pointer group">
            <div class="relative flex items-start">
              <div class="bg-slate-800/50 border border-slate-600/50 rounded w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 mt-0.5
          group-hover:border-blue-500/50 transition-colors">
                <input
                    type="checkbox"
                    v-model="termsAccepted"
                    class="opacity-0 absolute cursor-pointer"
                >
                <svg
                    class="fill-current w-3 h-3 text-blue-400 pointer-events-none"
                    viewBox="0 0 20 20"
                    :class="{ 'hidden': !termsAccepted }"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                </svg>
              </div>
            </div>
            <div class="flex-1">
      <span class="select-none text-slate-300">
        I have read and agree to the
        <router-link to="/terms" target="_blank"
                     class="text-blue-400 hover:text-blue-300">Terms and Conditions</router-link> and
        <router-link to="/privacy" target="_blank"
                     class="text-blue-400 hover:text-blue-300">Privacy Policy</router-link>.
        <span class="text-red-400 text-sm">*</span>
      </span>
            </div>
          </label>
        </div>

        <!-- Submit Button -->
        <button
            type="submit"
            :disabled="sessionStore.loading || !subscriptionInfo.hasActiveSubscription || (!sessionStore.currentUser?.active_period?.consent_given && !termsAccepted)"
            class="w-full px-6 py-4 bg-blue-600 hover:bg-blue-500 disabled:bg-blue-600/50
         text-white rounded-lg text-lg font-semibold
         disabled:cursor-not-allowed transition-all duration-200
         flex items-center justify-center space-x-3 group"
        >
  <span v-if="!sessionStore.loading" class="flex items-center justify-center space-x-2">
    <Sparkles class="h-5 w-5 group-hover:scale-110 transition-transform"/>
    <span>Begin Your Adventure</span>
    <ArrowRight class="h-5 w-5 group-hover:translate-x-1 transition-transform"/>
  </span>
          <span v-else class="flex items-center space-x-2">
    <span>Creating Your Story</span>
    <LoadingDots/>
  </span>
        </button>
      </form>
    </div>

    <ErrorBox/>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref} from 'vue'
import {useSessionStore} from '@/store/session'
import {useRoute, useRouter} from 'vue-router'
import LoadingDots from '@/components/LoadingDots.vue'
import ErrorBox from "@/components/ErrorBox.vue"
import SubscriptionBanner from "@/components/SubscriptionBanner.vue"
import {ArrowRight, RefreshCw, Sparkles} from 'lucide-vue-next'
import {useMetaTags} from "@/components/useMetaTags"
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import {useSubscription} from "@/composables/useSubscription";

const sessionStore = useSessionStore()
const router = useRouter()
const route = useRoute()

const initialPrompt = ref('')
const ageBand = ref('5-7')
const length = ref('6')
const promptNotSuitable = ref(false)
const notSuitableExplanation = ref('')
const termsAccepted = ref(false)

// New state for inspirations
const inspirations = ref<string[]>([])
const currentInspiration = ref('')
const loadingInspirations = ref(false)

const {subscriptionInfo} = useSubscription()

// Load inspirations from file
async function loadInspirations() {
  try {
    const response = await fetch('/inspirations.txt')
    if (!response.ok) throw new Error('Failed to load inspirations')
    const text = await response.text()
    inspirations.value = text.split('\n').filter(line => line.trim())
  } catch (error) {
    console.error('Error loading inspirations:', error)
  }
}

// Get a new random inspiration
function getNewInspiration() {
  if (inspirations.value.length === 0) return

  let newInspiration
  do {
    const randomIndex = Math.floor(Math.random() * inspirations.value.length)
    newInspiration = inspirations.value[randomIndex]
  } while (newInspiration === currentInspiration.value && inspirations.value.length > 1)

  currentInspiration.value = newInspiration
  initialPrompt.value = newInspiration
}

const createNewSession = async () => {
  if (!subscriptionInfo.value.hasActiveSubscription) return

  const result = await sessionStore.createNewSession({
    initial_prompt: initialPrompt.value,
    age_band: ageBand.value,
    length: parseInt(length.value),
  }, termsAccepted.value)

  if (result) {
    if (result.is_suitable_for_children && result.session) {
      router.push(`/sessions/${result.session.id}`)
    } else {
      promptNotSuitable.value = true
      notSuitableExplanation.value = result.not_suitable_for_children_explanation || 'The prompt is not suitable for children.'
    }
  }
}

const ageEmoji = (age: string) => {
  switch (age) {
    case '3-4':
      return '👶'
    case '5-7':
      return '🧒'
    case '8+':
      return '🧑'
    default:
      return '👤'
  }
}

const lengthEmoji = (value: string) => {
  switch (value) {
    case '4':
      return '🐁'
    case '6':
      return '🐈'
    case '8':
      return '🐘'
    default:
      return '📏'
  }
}

onMounted(async () => {
  useMetaTags({
    title: 'New Story',
    path: route.path,
  })

  if (!sessionStore.currentUser) {
    await sessionStore.fetchUser()
  }
  ageBand.value = sessionStore.currentUser?.default_session_params?.age_band || '5-7'
  length.value = (sessionStore.currentUser?.default_session_params?.length || 6).toString()

  await loadInspirations()
})
</script>