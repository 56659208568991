import {getCurrentUser} from 'vuefire'
import type {Ref} from "vue";

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL

interface ApiState {
    error: Ref<ApiError | null>;
    loading: Ref<boolean>;
}

export class ApiError extends Error {
    constructor(public statusCode: number, statusString: string, message: string) {
        super(message);
        this.name = statusString;
    }
}

const MAX_RETRIES = 1

interface RetryConfig {
    retryCount: number;
    needsTokenRefresh?: boolean;
}

const fetchWithAuth = async (url: string, options: RequestInit = {}, retryConfig: RetryConfig = {retryCount: 0}): Promise<Response> => {
    const user = await getCurrentUser()
    const headers = new Headers(options.headers)

    if (user) {
        // If we need a fresh token, force a token refresh
        if (retryConfig.needsTokenRefresh) {
            await user.reload()
            await user.getIdToken(true) // Force token refresh
        }

        const token = await user.getIdToken()
        headers.set('Authorization', `Bearer ${token}`)
        headers.set('Content-Type', 'application/json')
    }

    const response = await fetch(`${API_BASE_URL}${url}`, {
        ...options,
        headers,
    })

    // If we get a forbidden response and haven't retried yet, try refreshing the token
    if (response.status === 412 && retryConfig.retryCount < MAX_RETRIES) {
        console.log('Got 412, retrying with token refresh...')
        return fetchWithAuth(url, options, {
            retryCount: retryConfig.retryCount + 1,
            needsTokenRefresh: true
        })
    }

    return response
}

const handleApiError = (error: unknown, state: ApiState): void => {
    if (error instanceof ApiError) {
        // Add Help page reference for certain error types
        if (error.statusCode === 500 || error.statusCode === 503) {
            error.message = `${error.message}. If this issue persists, please check our Help page for more information and support.`
        }
        state.error.value = error
    } else if (error instanceof Error) {
        state.error.value = new ApiError(500, "Internal Error", `${error.message}. If this issue persists, please check our Help page for more information and support.`)
    } else {
        state.error.value = new ApiError(500, "Internal Error", 'An unknown error occurred. If this issue persists, please check our Help page for more information and support')
    }
}

export const apiRequest = async <T>(
    url: string,
    options: RequestInit = {},
    state: ApiState
): Promise<T | null> => {
    try {
        state.loading.value = true
        state.error.value = null
        const response = await fetchWithAuth(url, options)
        if (!response.ok) {
            const errorText = await response.text()
            throw new ApiError(response.status, response.statusText, errorText)
        }
        return await response.json() as T
    } catch (error) {
        handleApiError(error, state)
    } finally {
        state.loading.value = false
    }
    return null
}