<template>
  <footer class="mt-16 bg-slate-800/50 border-t border-slate-700/50">
    <div class="max-w-6xl mx-auto">
      <!-- Main Footer Content -->
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 px-6 py-12">
        <!-- Description Section -->
        <div class="col-span-1 space-y-4">
          <p class="text-slate-400">
            Transform storytime into an interactive adventure where children shape their own magical tales through meaningful choices.
          </p>
          <div class="flex items-center space-x-4">
            <a href="https://x.com/KidsTellTales"
               target="_blank"
               rel="noopener noreferrer"
               class="text-slate-400 hover:text-blue-400 transition-colors">
              <Twitter class="h-5 w-5"/>
              <span class="sr-only">Twitter</span>
            </a>
            <a href="https://www.facebook.com/people/Kids-Tell-Tales/61569566125673/"
               target="_blank"
               rel="noopener noreferrer"
               class="text-slate-400 hover:text-blue-400 transition-colors">
              <Facebook class="h-5 w-5"/>
              <span class="sr-only">Facebook</span>
            </a>
            <a href="https://discord.gg/h7Xrhqsbhv"
               target="_blank"
               rel="noopener noreferrer"
               class="text-slate-400 hover:text-blue-400 transition-colors">
              <MessagesSquare class="h-5 w-5"/>
              <span class="sr-only">Discord</span>
            </a>
          </div>
        </div>

        <!-- Quick Links -->
        <div class="space-y-4">
          <h3 class="text-sm font-semibold text-slate-300 uppercase tracking-wider">Quick Links</h3>
          <ul class="space-y-3">
            <li>
              <router-link to="/" class="text-slate-400 hover:text-blue-400 transition-colors">
                Home
              </router-link>
            </li>
            <li>
              <router-link to="/purchase" class="text-slate-400 hover:text-blue-400 transition-colors">
                Pricing
              </router-link>
            </li>
            <li>
              <router-link to="/help" class="text-slate-400 hover:text-blue-400 transition-colors">
                Help & FAQ
              </router-link>
            </li>
          </ul>
        </div>

        <!-- Contact -->
        <div class="space-y-4">
          <h3 class="text-sm font-semibold text-slate-300 uppercase tracking-wider">Contact</h3>
          <ul class="space-y-3">
            <li>
              <a href="mailto:support@kidstelltales.com"
                 class="text-slate-400 hover:text-blue-400 transition-colors flex items-center space-x-2">
                <Mail class="h-4 w-4"/>
                <span>support@kidstelltales.com</span>
              </a>
            </li>
            <li>
              <a href="https://discord.gg/h7Xrhqsbhv"
                 target="_blank"
                 rel="noopener noreferrer"
                 class="text-slate-400 hover:text-blue-400 transition-colors flex items-center space-x-2">
                <MessagesSquare class="h-4 w-4"/>
                <span>Join our Discord</span>
              </a>
            </li>
            <li>
              <button
                  @click="openCookieSettings"
                  class="text-slate-400 hover:text-blue-400 transition-colors flex items-center space-x-2">
                <Cookie class="h-4 w-4"/>
                <span>Cookie Settings</span>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <!-- Bottom Bar remains the same -->
      <div class="border-t border-slate-700/50 px-6 py-4">
        <div class="max-w-6xl mx-auto">
          <div class="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 justify-between">
            <p class="text-center md:text-left text-sm text-slate-400">
              &copy; {{ currentYear }} Kids Tell Tales. All rights reserved.
            </p>

            <!-- Links (on top for mobile) -->
            <div class="flex flex-wrap justify-center gap-x-6 gap-y-2">
              <a href="/privacy" class="hover:text-blue-400 transition-colors text-sm">Privacy Policy</a>
              <a href="/terms" class="hover:text-blue-400 transition-colors text-sm">Terms of Service</a>
              <a href="/dmca" class="hover:text-blue-400 transition-colors text-sm">DMCA and Takedowns Policy</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { Facebook, Twitter, Instagram, Mail, Cookie, MessagesSquare } from 'lucide-vue-next'

const currentYear = computed(() => new Date().getFullYear())

const emit = defineEmits(['openCookieSettings'])

const openCookieSettings = () => {
  emit('openCookieSettings')
}
</script>