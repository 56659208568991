<template>
  <LoadingSkeleton v-if="sessionStore.loading && !sessionStore.currentUser" text="Loading your account"/>
  <div v-else>
    <!-- Header -->
    <div class="p-6 bg-gradient-to-b from-slate-800/50 to-transparent">
      <h1 class="text-3xl md:text-4xl font-display font-bold text-slate-100">
        Account
      </h1>
    </div>

    <div class="px-6 pb-6">
      <div v-if="sessionStore.currentUser" class="max-w-2xl space-y-8">
        <!-- Profile Section -->
        <div class="rounded-xl bg-slate-800/50 backdrop-blur-sm border border-slate-700/50 overflow-hidden">
          <div class="p-6 space-y-6">
            <!-- Profile Info -->
            <div class="flex flex-col md:flex-row md:items-center justify-between gap-6">
              <div class="flex items-center gap-4">
                <div class="flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full
                            bg-gradient-to-r from-blue-600/20 to-indigo-600/20
                            border border-blue-500/20">
                  <User2 class="h-7 w-7 text-blue-400"/>
                </div>
                <div>
                  <h2 class="text-xl font-display font-semibold text-slate-100">
                    {{ user?.displayName || 'Storyteller' }}
                  </h2>
                  <p class="text-slate-400">{{ user?.email }}</p>
                </div>
              </div>

              <button
                  @click="handleSignOut"
                  class="flex items-center justify-center px-4 py-2 rounded-lg
                         bg-slate-800/50 border border-slate-600/50
                         text-slate-300 hover:text-white hover:bg-slate-700
                         transition-all duration-200 group gap-2"
              >
                <LogOut class="h-5 w-5 group-hover:-translate-x-0.5 transition-transform"/>
                <span>Sign Out</span>
              </button>
            </div>

            <!-- Marketing Preferences -->
            <div class="pt-6">
            <div class="space-y-4">
              <h3 class="font-medium text-slate-200">Marketing Preferences</h3>

              <label class="flex items-start space-x-3 cursor-pointer group">
                <div class="relative flex items-start">
                  <div class="bg-slate-800/50 border border-slate-600/50 rounded w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 mt-0.5
          group-hover:border-blue-500/50 transition-colors">
                    <!-- Loading Spinner (shown when loading) -->
                    <div v-if="isLoading"
                         class="w-3 h-3 border-2 border-blue-400/20 border-t-blue-400 rounded-full animate-spin">
                    </div>
                    <!-- Checkbox (hidden when loading) -->
                    <template v-else>
                      <input
                          type="checkbox"
                          :checked="sessionStore.currentUser?.email_marketing"
                          class="opacity-0 absolute cursor-pointer"
                          @change="toggleMarketingPreferences"
                      >
                      <svg
                          class="fill-current w-3 h-3 text-blue-400 pointer-events-none"
                          viewBox="0 0 20 20"
                          :class="{ 'hidden': !sessionStore.currentUser?.email_marketing }"
                      >
                        <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                      </svg>
                    </template>
                  </div>
                </div>
                <div class="flex-1">
      <span class="select-none text-slate-300">
        Sign me up for updates and special offers. I accept the
        <a href="/newsletter-terms" target="_blank" class="text-blue-400 hover:text-blue-300">Newsletter Terms</a>
      </span>
                </div>
              </label>
            </div>
            </div>
          </div>
        </div>

        <!-- Subscription Banner -->
        <SubscriptionBanner/>

        <!-- Subscription Details -->
        <div v-if="subscriptionInfo.hasActiveSubscription"
             class="rounded-xl bg-slate-800/50 backdrop-blur-sm border border-slate-700/50 overflow-hidden">
          <div class="p-6 space-y-6">
            <div class="flex items-center gap-3">
              <div class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full
                          bg-emerald-600/10 border border-emerald-500/20">
                <Gamepad2 class="h-5 w-5 text-emerald-400"/>
              </div>
              <h3 class="text-lg font-display font-semibold text-slate-100">
                Active Subscription
              </h3>
            </div>

            <div class="grid gap-4 sm:grid-cols-2">
              <div class="flex items-start gap-3 p-4 rounded-lg bg-slate-800/50 border border-slate-700/50">
                <Calendar class="h-5 w-5 text-slate-400 flex-shrink-0 mt-0.5"/>
                <div>
                  <p class="text-sm font-medium text-slate-400">Valid Until</p>
                  <p class="text-slate-200">{{ formatDate(subscriptionInfo.subscriptionEndDate) }}</p>
                </div>
              </div>

              <div class="flex items-start gap-3 p-4 rounded-lg bg-slate-800/50 border border-slate-700/50">
                <Sparkles class="h-5 w-5 text-slate-400 flex-shrink-0 mt-0.5"/>
                <div>
                  <p class="text-sm font-medium text-slate-400">Remaining Interactions</p>
                  <p class="text-slate-200">{{ subscriptionInfo.interactionsLeft }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ErrorBox/>
</template>

<script setup lang="ts">
import {computed, onMounted, ref} from 'vue'
import { useCurrentUser, useFirebaseAuth } from 'vuefire'
import { signOut } from 'firebase/auth'
import { useRoute, useRouter } from 'vue-router'
import { useSessionStore } from '@/store/session'
import { Calendar, Gamepad2, LogOut, Sparkles, User2 } from 'lucide-vue-next'
import { useMetaTags } from "@/components/useMetaTags"
import LoadingSkeleton from "@/components/LoadingSkeleton.vue"
import ErrorBox from "@/components/ErrorBox.vue"
import SubscriptionBanner from "@/components/SubscriptionBanner.vue"
import { useSubscription } from "@/composables/useSubscription"

const user = useCurrentUser()
const auth = useFirebaseAuth()
const router = useRouter()
const route = useRoute()
const sessionStore = useSessionStore()

const { subscriptionInfo, formatDate } = useSubscription()

const isLoading = ref(false)

const toggleMarketingPreferences = async () => {
  isLoading.value = true
  try {
    const newValue = !sessionStore.currentUser?.email_marketing
    await sessionStore.setMarketingPreferences(newValue, false)
  } finally {
    isLoading.value = false
  }
}

async function handleSignOut() {
  try {
    sessionStore.clearStore()
    await signOut(auth)
    router.push('/')
  } catch (e) {
    console.error('Sign out error:', e)
  }
}

onMounted(async () => {
  useMetaTags({
    title: 'Account',
    path: route.path,
  })

  if (!sessionStore.currentUser) {
    await sessionStore.fetchUser()
  }
})
</script>