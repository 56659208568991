<template>
  <div class="min-h-screen">

    <div>
      <div class="aspect-[4/3] relative">
        <img
            src="/kid-astronaut.jpg"
            alt="Kid astronaut floating in space"
            class="w-full h-full object-contain animate-float"
            loading="lazy"
        />
      </div>
    </div>
    <!-- Hero Section -->
    <div class="relative overflow-hidden px-6 pt-12 pb-16 lg:px-8">
      <div class="absolute inset-0">
        <div class="absolute w-72 h-72 bg-blue-600/10 rounded-full blur-3xl -top-32 -right-32"></div>
        <div class="absolute w-72 h-72 bg-indigo-600/10 rounded-full blur-3xl -bottom-32 -left-32"></div>
      </div>

      <div class="relative max-w-3xl mx-auto text-center">

        <h1 class="text-4xl md:text-6xl font-display font-bold bg-gradient-to-r from-blue-400 to-indigo-400 text-transparent bg-clip-text">
          Create Magical Stories with Your Children
        </h1>
        <p class="mt-6 text-lg md:text-xl text-slate-300">
          Personalized AI adventures that spark your child's imagination
        </p>

        <!-- Call to Action Buttons -->
        <div class="flex flex-col sm:flex-row justify-center gap-4 mt-12">
          <button
              @click="redirectToLogin"
              class="inline-flex items-center justify-center px-8 py-4 text-lg font-semibold
           bg-gradient-to-r from-blue-600 to-indigo-600
           hover:from-blue-500 hover:to-indigo-500
           text-white rounded-full shadow-lg
           hover:shadow-blue-500/25 hover:-translate-y-0.5
           transform transition-all duration-200
           w-full sm:w-auto"
          >
            <Sparkles class="h-5 w-5 mr-2 animate-pulse" />
            <span>Try it out for free!</span>
          </button>

          <button
              @click="toggleVideo"
              class="inline-flex items-center justify-center px-8 py-4 text-lg font-semibold
           bg-slate-800/50 border border-slate-700/50
           hover:bg-slate-700/50 hover:border-slate-600/50
           text-slate-300 hover:text-white rounded-full shadow-lg
           transform transition-all duration-200 group
           w-full sm:w-auto"
          >
            <Play class="h-5 w-5 mr-2 group-hover:scale-110 transition-transform" />
            <span>Watch our video</span>
            <ChevronDown v-if="!showVideo" class="ml-2 h-5 w-5" />
            <ChevronUp v-else class="ml-2 h-5 w-5"/>
          </button>
        </div>

        <!-- Video Section -->
        <Transition
            enter-active-class="transition-all duration-500 ease-out"
            enter-from-class="opacity-0 -translate-y-4"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition-all duration-200 ease-in"
            leave-from-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 -translate-y-4"
        >
          <div v-if="showVideo" class="mt-8"  ref="videoContainer">
            <div class="relative pb-[56.25%] h-0 rounded-xl overflow-hidden bg-slate-800/50 border border-slate-700/50">
              <iframe
                  class="absolute inset-0 w-full h-full"
                  src="https://player.vimeo.com/video/1043490484?h=1a8e78eec8"
                  allow="autoplay; fullscreen; picture-in-picture"
                  title="Kids Tell Tales Demo Video"
                  loading="lazy"
              ></iframe>
            </div>
          </div>
        </Transition>
      </div>
    </div>

    <!-- How It Works Section -->
    <div class="px-6 py-10 bg-slate-800/30 border-y border-slate-700/50">
      <div class="max-w-4xl mx-auto">
        <h2 class="text-2xl md:text-3xl font-display font-bold text-center text-slate-100 mb-12">
          How It Works
        </h2>

        <div class="space-y-16">
          <!-- Step 1 -->
          <div class="flex flex-col md:flex-row items-center gap-8">
            <div class="w-full md:w-1/2 order-2 md:order-1">
              <div class="bg-slate-800/50 rounded-xl border border-slate-700/50 overflow-hidden">
                <img src="/create_new_story.jpg" alt="Create a story screen" class="w-full" />
              </div>
            </div>
            <div class="w-full md:w-1/2 order-1 md:order-2">
              <div class="space-y-4">
                <div class="inline-flex items-center space-x-2 text-blue-400">
                  <div class="flex h-8 w-8 items-center justify-center rounded-full bg-blue-600/10 border border-blue-500/20">
                    <span class="text-lg font-semibold">1</span>
                  </div>
                  <h3 class="text-xl font-display font-semibold">Start Your Story</h3>
                </div>
                <p class="text-slate-300">
                  Begin by entering any story idea you'd like. Choose your child's age group and preferred story length. Our AI storyteller will craft a unique tale based on your input.
                </p>
              </div>
            </div>
          </div>

          <!-- Step 2 -->
          <div class="flex flex-col md:flex-row items-center gap-8">
            <div class="w-full md:w-1/2">
              <div class="space-y-4">
                <div class="inline-flex items-center space-x-2 text-blue-400">
                  <div class="flex h-8 w-8 items-center justify-center rounded-full bg-blue-600/10 border border-blue-500/20">
                    <span class="text-lg font-semibold">2</span>
                  </div>
                  <h3 class="text-xl font-display font-semibold">Shape the Adventure</h3>
                </div>
                <p class="text-slate-300">
                  As the story unfolds, you'll be presented with three choices at key moments. Each choice leads to different outcomes, allowing your child to guide the narrative in their own unique way.
                </p>
              </div>
            </div>
            <div class="w-full md:w-1/2">
              <div class="bg-slate-800/50 rounded-xl border border-slate-700/50 overflow-hidden">
                <img src="/choice.jpg" alt="Story choices screen" class="w-full" />
              </div>
            </div>
          </div>

          <!-- Step 3 -->
          <div class="flex flex-col md:flex-row items-center gap-8">
            <div class="w-full md:w-1/2 order-2 md:order-1">
              <div class="bg-slate-800/50 rounded-xl border border-slate-700/50 overflow-hidden">
                <img src="/stories.jpg" alt="Save and share screen" class="w-full" />
              </div>
            </div>
            <div class="w-full md:w-1/2 order-1 md:order-2">
              <div class="space-y-4">
                <div class="inline-flex items-center space-x-2 text-blue-400">
                  <div class="flex h-8 w-8 items-center justify-center rounded-full bg-blue-600/10 border border-blue-500/20">
                    <span class="text-lg font-semibold">3</span>
                  </div>
                  <h3 class="text-xl font-display font-semibold">Save and Share</h3>
                </div>
                <p class="text-slate-300">
                  Your stories are automatically saved in your library. Once complete, you can share them with family and friends. Return to your saved stories anytime to relive the adventures or create new ones.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SampleStories  ref="sampleStories"/>

    <!-- Testimonials -->
    <div class="px-6 py-10 bg-slate-800/30 border-y border-slate-700/50">
      <div class="max-w-3xl mx-auto">
        <h2 class="text-2xl md:text-3xl font-display font-bold text-center text-slate-100 mb-12">
          Parents Love Our Stories
        </h2>

        <div class="grid md:grid-cols-2 gap-6">
          <div
              v-for="(testimonial, index) in testimonials"
              :key="index"
              class="p-6 bg-slate-800/50 rounded-xl border border-slate-700/50"
          >
            <div class="flex items-start space-x-4">
              <div class="flex-shrink-0">
                <div
                    class="w-12 h-12 rounded-full bg-gradient-to-r from-blue-600/20 to-indigo-600/20 flex items-center justify-center">
                  <User class="h-6 w-6 text-blue-400"/>
                </div>
              </div>
              <div>
                <div class="text-yellow-400 mb-2">★★★★★</div>
                <p class="text-slate-300 mb-2">{{ testimonial.text }}</p>
                <p class="text-sm text-slate-400">{{ testimonial.author }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Final CTA -->
    <div class="px-6 py-16">
      <div class="max-w-2xl mx-auto text-center space-y-6">
        <h2 class="text-2xl md:text-3xl font-display font-bold text-slate-100">
          Ready to Create Your Own Stories?
        </h2>
        <button
            @click="redirectToLogin"
            class="group inline-flex items-center justify-center px-8 py-4 bg-blue-600 hover:bg-blue-500 text-white rounded-lg text-lg font-semibold transition-all duration-200"
        >
          <Sparkles class="h-5 w-5 mr-2 group-hover:scale-110 transition-transform"/>
          <span>Start your trial now!</span>
          <ArrowRight class="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform"/>
        </button>
      </div>
    </div>


  </div>
</template>

<script setup lang="ts">
import {nextTick, onMounted, ref} from 'vue'
import {ArrowRight, Book, Brain, ChevronDown, Play, Sparkles, Star, User, ChevronUp} from 'lucide-vue-next'
import {useRoute, useRouter} from 'vue-router'
import Demo from '@/components/Demo.vue'
import {useMetaTags} from "@/components/useMetaTags"
import {scrollToElement} from "@/utils/scroll";
import SampleStories from "@/components/SampleStories.vue";

const router = useRouter()
const route = useRoute()
const showVideo = ref(false)

const videoContainer = ref<HTMLElement | null>(null)
const sampleStories = ref<HTMLElement | null>(null)

const redirectToLogin = () => {
  router.push('/purchase')
}

async function toggleVideo() {
  showVideo.value = !showVideo.value

  await nextTick()

  if(showVideo.value) {
    scrollToElement(videoContainer.value!)
  }
}

onMounted(async () => {
  useMetaTags({
    title: '',
    path: route.path,
  })
})


const features = [
  {
    icon: Book,
    title: "Interactive Choices",
    description: "Children make meaningful decisions that shape their unique adventure"
  },
  {
    icon: Star,
    title: "Age-Appropriate",
    description: "Stories perfectly tailored for ages 3 and above, supporting their development"
  },
  {
    icon: Brain,
    title: "AI-Powered Magic",
    description: "Advanced AI ensures each story is unique and engaging"
  },
  {
    icon: Sparkles,
    title: "Educational Value",
    description: "Stories that entertain while supporting learning and development"
  }
]

const testimonials = [
  {
    text: "My daughter can't wait for our daily story time now. The interactive choices make her feel like she's part of the adventure!",
    author: "Patricia P., Mom of 6-year-old"
  },
  {
    text: "Finally found something that keeps both my 3 and 7 year olds engaged. The stories are magical and educational!",
    author: "Przemek P., Father of two"
  }
]
</script>

<style scoped>
.animate-bounce-sparkle {
  animation: bounce-sparkle 3s infinite;
}

@keyframes bounce-sparkle {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-25%);
  }
}
</style>