<!-- File: src/components/SampleStories.vue -->
<template>
  <div class="px-6 py-10">
    <div class="max-w-3xl mx-auto">
      <h2 class="text-2xl md:text-3xl font-display font-bold text-center text-slate-100 mb-8">
        Explore Sample Stories
      </h2>

      <!-- Age Band Filter -->
      <div class="flex justify-center gap-2 mb-8">
        <button
            @click="selectedAgeBand = 'all'"
            :class="[
            'px-3 py-1.5 rounded-full transition-all duration-200 border',
            selectedAgeBand === 'all'
              ? 'bg-blue-600/20 border-blue-500/50 text-blue-400'
              : 'bg-slate-800/50 border-slate-600/50 text-slate-300 hover:border-blue-500/30 hover:bg-blue-600/10'
          ]"
        >
          All Ages
        </button>
        <button
            v-for="age in ['3-4', '5-7', '8+']"
            :key="age"
            @click="selectedAgeBand = age"
            :class="[
            'px-3 py-1.5 rounded-full transition-all duration-200 border',
            selectedAgeBand === age
              ? 'bg-blue-600/20 border-blue-500/50 text-blue-400'
              : 'bg-slate-800/50 border-slate-600/50 text-slate-300 hover:border-blue-500/30 hover:bg-blue-600/10'
          ]"
        >
          <span class="mr-2">{{ getAgeEmoji(age) }}</span>
          <span>{{ age }} years</span>
        </button>
      </div>

      <!-- Story Cards Grid -->
      <div class="grid md:grid-cols-2 gap-6">
        <router-link
            v-for="story in filteredStories"
            :key="story.title"
            :to="story.url"
            class="group block p-6 bg-slate-800/50 backdrop-blur border border-slate-700/50 rounded-xl
                hover:border-blue-500/20 transition-all duration-300 hover:-translate-y-1
                hover:bg-gradient-to-r hover:from-blue-600/10 hover:via-indigo-600/10 hover:to-blue-600/10
                cursor-pointer relative"
        >
          <!-- Card Content -->
          <div class="space-y-4">
            <div class="space-y-1">
              <h3 class="text-xl font-display font-semibold text-slate-100 group-hover:text-blue-400 transition-colors">
                {{ story.title }}
              </h3>
              <div class="flex items-center gap-3 text-sm text-slate-400">
                <span class="flex items-center gap-1">
                  <span>{{ getAgeEmoji(story.ageBand) }}</span>
                  <span>{{ story.ageBand }}</span>
                </span>
                <span class="flex items-center gap-1">
                  {{ getLengthEmoji(story.length) }} {{ story.length }} chapters
                </span>
              </div>
            </div>

            <p class="text-slate-300">
              {{ story.description }}
            </p>

            <!-- Visual indicator for clickable card -->
            <div class="flex items-center text-slate-400 group-hover:text-blue-400 transition-colors">
              <span class="text-sm">Read story</span>
              <ArrowRight class="h-4 w-4 ml-1 group-hover:translate-x-1 transition-transform"/>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { ArrowRight } from 'lucide-vue-next'

const selectedAgeBand = ref('all')

const sampleStories = [
  {
    title: "The Incredible Immune Team",
    ageBand: "8+",
    length: "9",
    description: "'Code Red! Virus detected!' announced General White Blood Cell, adjusting his microscopic commander hat. 'We need to assemble our best team to fight this cold virus!'",
    url: "sessions/the-incredible-immune-team-441a2cf745aac59e",
  },
  {
    title: "The Littlest Boss",
    ageBand: "8+",
    length: "7",
    description: "\"From now on,\" Tommy announced with his chest puffed out, \"I'm the boss of this family! No more bedtime, and ice cream for breakfast!\"",
    url: "sessions/the-littlest-boss-eedf847dc6bbd671",
  },
  {
    title: "The Cool Parents Project",
    ageBand: "5-7",
    length: "9",
    description: "Max was tired of his parents being so... parent-like. One day, during breakfast, he decided it was time for a change.",
    url: "sessions/the-cool-parents-project-89706253720af8d9",
  },
  {
    title: "The Furry City Mystery",
    ageBand: "5-7",
    length: "7",
    description: "In the city of Furbington, everything was covered in soft, colorful fur - from buildings to benches, from cars to traffic lights...",
    url: "sessions/the-furry-city-mystery-8df424d67080f739",
  },
  {
    title: "The Amazing Animal Talker",
    ageBand: "3-4",
    length: "7",
    description: "In a colorful playroom, four-year-old Emma and her best friend Lucas were working on something special. They loved watching animals in the backyard and wished they could talk to them!",
    url: "sessions/the-amazing-animal-talker-e0ac98e74d29e929",
  },
  {
    title: "Lisa's Rainbow Question",
    ageBand: "3-4",
    length: "5",
    description: "\"Daddy, why do rainbows appear?\" she asked.\n" +
        "\n" +
        "\"Well...\" Dad scratched his head. \"Because... um... magical sky fairies paint them!\"",
    url: "sessions/lisas-rainbow-question-d5938125f4c9669a",
  },
]

const filteredStories = computed(() =>
    selectedAgeBand.value === 'all'
        ? sampleStories
        : sampleStories.filter(story => story.ageBand === selectedAgeBand.value)
)

const getLengthEmoji = (length: string): string => {
  switch (length) {
    case "5": return "🐁"
    case "7": return "🐈"
    case "9": return "🐘"
    default: return "📏"
  }
}

const getAgeEmoji = (age: string): string => {
  switch (age) {
    case "3-4": return "👶"
    case "5-7": return "🧒"
    case "8+": return "🧑"
    default: return "👤"
  }
}
</script>