<template>
  <div class="min-h-screen">
    <div class="p-6 md:p-12">
      <div class="max-w-4xl mx-auto">
        <!-- Header -->
        <h1 class="text-3xl md:text-4xl font-display font-bold text-slate-100 mb-8">
          Privacy Policy
        </h1>

        <div class="prose prose-invert max-w-none">
          <!-- Introduction -->
          <section class="mb-8">
          <p class="text-slate-300 mb-4">
            This Privacy Policy (hereinafter: "Policy") contains information on the processing of your personal data in connection with the use of the App.
          </p>

          <p class="text-slate-300">
            All capitalized terms that are not otherwise defined in the Policy shall have the meaning given to them in the Terms and Conditions, available at: <a href="/terms" class="text-blue-400 hover:text-blue-300">https://kidstelltales.com/terms</a>.
          </p>
          </section>

          <section class="mb-8">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
              Personal data Controller
            </h2>
            <p class="text-slate-300">
              The Controller of your personal data is KIDS TELL TALES spółka z ograniczoną odpowiedzialnością (limited liability co.) based in Kraków, address: ul. Stelmachów 46b/2, 31-341 Kraków, Poland, entered into entrepreneurs' register administered by the District Court for Kraków- Śródmieście based in Kraków, XI Commercial Division of National Court Register under KRS no. 0001141843, holding Tax ID no. 9452302626, REGON: 54032510300000, with a share capital of 10 050 PLN (hereinafter: "Controller").
            </p>
          </section>

          <section class="mb-8">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
              Contact with the Controller
            </h2>
            <p class="text-slate-300">
              In all matters related to the processing of personal data, you can contact the Controller using e-mail - at: <a href="mailto:support@kidstelltales.com" class="text-blue-400 hover:text-blue-300">support@kidstelltales.com</a>.
            </p>
          </section>

          <section class="mb-8">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
              Measures to protect personal data
            </h2>
            <p class="text-slate-300">
              The Controller uses modern organizational and technical safeguards to ensure the best possible protection of your personal data and guarantees that it processes them in accordance with the provisions of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation) (hereinafter: "GDPR"), the Act of 10 May 2018 on the protection of personal data and other provisions on the protection of personal data.
            </p>
          </section>

          <section class="mb-8">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
              Information about the processed personal data
            </h2>
            <p class="text-slate-300 mb-6">
              Using the App requires the processing of your personal data. Below you will find detailed information about the purposes and legal bases of processing, as well as the period of processing and the obligation or voluntariness to provide them.
            </p>

            <div class="space-y-8">
              <div v-for="(purpose, index) in purposes" :key="index" class="bg-slate-800/50 rounded-lg p-6 border border-slate-700/50">
                <h3 class="text-2xl font-display font-semibold text-slate-100 mb-6">{{ purpose.title }}</h3>

                <div class="space-y-6">
                  <!-- Personal data section -->
                  <div>
                    <h4 class="text-lg font-medium text-slate-200 mb-3">Personal data processed:</h4>
                    <ul class="list-disc text-slate-300 space-y-2 ml-8">
                      <li v-for="data in purpose.data" :key="data">{{ data }}</li>
                    </ul>
                  </div>

                  <!-- Legal basis section -->
                  <div>
                    <h4 class="text-lg font-medium text-slate-200 mb-3">Legal basis:</h4>
                    <p class="text-slate-300">{{ purpose.legalBasis }}</p>
                  </div>

                  <!-- Data provision info -->
                  <div>
                    <h4 class="text-lg font-medium text-slate-200 mb-3">Provision of data:</h4>
                    <p class="text-slate-300">{{ purpose.provision }}</p>
                  </div>

                  <!-- Processing period -->
                  <div>
                    <h4 class="text-lg font-medium text-slate-200 mb-3">Processing period:</h4>
                    <p class="text-slate-300">{{ purpose.period }}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="mb-8">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
              Profiling
            </h2>
            <p class="text-slate-300">
              To create your profile for marketing purposes and direct marketing tailored to your preferences, the Controller will process your personal data in an automated manner, including profiling them -- however, this will not cause any legal effects for you or similarly significantly affect your situation.
            </p>
            <p class="text-slate-300 mt-4">
              The scope of profiled personal data corresponds to the scope indicated above in relation to the analysis of your activity in the App and the data you save on the Account.
            </p>
            <p class="text-slate-300 mt-4">
              The legal basis for the processing of personal data for the above purpose is art. 6 par. 1 lit. f GDPR, according to which the Controller may process personal data to implement his legitimate interest, in this case to conduct marketing activities tailored to the preferences of recipients. Providing the above-mentioned personal data is voluntary, but necessary to achieve the above-mentioned purpose (the consequence of not providing them will be the inability of the Controller to conduct marketing activities tailored to the preferences of recipients).
            </p>
            <p class="text-slate-300 mt-4">
              The Controller will process personal data for the purpose of profiling until an objection is effectively raised or the purpose of processing is achieved.
            </p>
          </section>

          <section class="mb-8">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
              Recipients of personal data
            </h2>
            <p class="text-slate-300">The recipients of personal data will be the following external entities cooperating with the Controller:</p>
            <ul class="list-disc pl-8 mt-4 space-y-2 text-slate-300">
              <li>hosting company;</li>
              <li>providers of intermediary services for logging into the App;</li>
              <li>providers of online payment systems (a.o. Stripe);</li>
              <li>companies providing tools to analyse activity in the App in order to develop it and detect any irregularities (a.o. Sentry.io);</li>
              <li>companies providing tools for analyzing activity in the App and directing direct marketing to people using it (a.o. Google Analytics);</li>
              <li>a company providing accounting services.</li>
            </ul>
            <p class="text-slate-300 mt-4">
              In addition, personal data may also be transferred to public or private entities, if such an obligation results from generally applicable law, a final court judgment or a final administrative decision.
            </p>
          </section>

          <section class="mb-8">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
              Transfer of personal data to a third country
            </h2>
            <p class="text-slate-300">
              In connection with the Controller's use of services provided by Google LLC, your personal data may be transferred to the following third countries: Great Britain, Canada, USA, Chile, Brazil, Israel, Saudi Arabia, Qatar, India, China, South Korea, Japan, Singapore, Taiwan (Republic of China), Indonesia and Australia. The basis for the transfer of data to the above-mentioned third countries are:
            </p>
            <ol class="list-decimal pl-8 mt-4 space-y-2 text-slate-300">
              <li>in the case of Great Britain, Canada, Israel, Japan and South Korea - European Commission decisions confirming an adequate level of personal data protection in each of these third countries;</li>
              <li>in the case of USA - Commission Implementing Decision (EU) 2023/1795 of 10 July 2023 pursuant to Regulation (EU) 2016/679 of the European Parliament and of the Council on the adequate protection of personal data under the EU-U.S. Data Privacy Framework;</li>
              <li>in the case of Chile, Brazil, Saudi Arabia, Qatar, India, China, Singapore, Taiwan (Republic of China), Indonesia and Australia - contractual clauses ensuring an adequate level of protection, compliant with standard contractual clauses set out in Commission Implementing Decision (EU) 2021/914 of 4 June 2021 on standard contractual clauses for the transfer of personal data to third countries pursuant to Regulation (EU) 2016/679 of the European Parliament and of the Council.</li>
            </ol>
          </section>

          <section class="mb-8">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
              Your rights
            </h2>
            <p class="text-slate-300">In connection with the processing of personal data, you have the following rights:</p>
            <ol class="list-decimal pl-8 mt-4 space-y-2 text-slate-300">
              <li>the right to information about what personal data concerning you is processed by the Controller and to receive a copy of this data (the so-called right of access). Issuing the first copy of the data is free, for the next one the Controller may charge a fee;</li>
              <li>if the processed data becomes outdated or incomplete (or otherwise incorrect), you have the right to request its rectification;</li>
              <li>in certain situations, you can ask the Controller to delete your personal data, e.g. when:
                <ul class="list-[lower-alpha] pl-8 mt-2 space-y-2">
                  <li>the data will no longer be needed by the Controller for the purposes of which he informed;</li>
                  <li>you have effectively withdrawn your consent to the processing of data - unless the Controller has the right to process data on another legal basis;</li>
                  <li>the processing is unlawful;</li>
                  <li>the need to delete data results from the Controller's legal obligation.</li>
                </ul>
              </li>
              <li>if personal data are processed by the Controller on the basis of consent to processing or for the purpose of performing the Agreement concluded with him, you have the right to transfer your data to another Controller;</li>
              <li>if personal data is processed by the Controller on the basis of your consent to processing, you have the right to withdraw this consent at any time (withdrawal of consent does not affect the lawfulness of processing that was made on the basis of consent before its withdrawal);</li>
              <li>if you consider that the processed personal data is incorrect, their processing is unlawful, or the Controller no longer needs certain data, you can request that for a specific, necessary time (e.g. checking the correctness of data or pursuing claims) the Controller does not perform any operations on the data, but only Apps them;</li>
              <li>you have the right to object to the processing of personal data whose basis for processing is the legitimate interest of the Controller. In the event of an effective objection, the Controller will cease to process personal data for the above-mentioned purpose;</li>
              <li>you have the right to lodge a complaint with the President of the Office for Personal Data Protection if you believe that the processing of personal data violates the provisions of the GDPR.</li>
            </ol>
          </section>

          <section class="mb-8">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
              Cookies
            </h2>
            <ol class="list-decimal pl-8 mt-4 space-y-4 text-slate-300">
              <li>The Controller informs that the App uses "cookies" installed on your end device. These are small text files that can be read by the Controller's system, as well as by systems belonging to other entities whose services are used by the Controller (e.g. Facebook, Google).</li>
              <li>The Controller uses cookies for the following purposes:
                <ul class="list-[lower-alpha] pl-8 mt-2 space-y-2">
                  <li><span class="underline">ensuring the proper operation of the App</span> -- thanks to cookies, it is possible to operate efficiently the App, use its functions and conveniently move between individual subpages;</li>
                  <li><span class="underline">increasing the comfort of browsing the App</span> -- thanks to cookies, it is possible to detect errors on some subpages and their constant improvement;</li>
                  <li><span class="underline">creating statistics</span> -- cookies are used to analyze how users use the App. Thanks to this, it is possible to constantly improve the App and adapt its operation to the preferences of users;</li>
                  <li><span class="underline">conducting marketing activities</span> -- thanks to cookies, the Controller may direct advertisements tailored to users' preferences.</li>
                </ul>
              </li>
              <li>Controller can place both permanent and temporary (session) files on your device. Session files are usually deleted when you close the browser, but closing the browser does not delete persistent files.</li>
              <li>Information about cookies used by the Controller is displayed in the panel located at the bottom of the App's website. Depending on your decision, you can enable or disable cookies of individual categories (except for necessary cookies) and change these settings at any time.</li>
              <li>Data collected using cookies do not allow the Controller to identify you.</li>
              <li>The Controller uses the following cookies or tools using them:</li>
            </ol>

            <div class="mt-6 overflow-x-auto">
              <table class="w-full text-left border-collapse">
                <thead>
                <tr class="border-b border-slate-700">
                  <th class="py-4 px-6 text-slate-200">Tool</th>
                  <th class="py-4 px-6 text-slate-200">Supplier</th>
                  <th class="py-4 px-6 text-slate-200">Functions and scope of downloaded data</th>
                  <th class="py-4 px-6 text-slate-200">Period of operation</th>
                </tr>
                </thead>
                <tbody class="text-slate-300">
                <tr class="border-b border-slate-700">
                  <td class="py-4 px-6">Essential cookies</td>
                  <td class="py-4 px-6">Controller</td>
                  <td class="py-4 px-6">The operation of these files is necessary for the proper functioning of the App, so you can not disable them. Thanks to these files (collecting, m.in. the IP number of your device), it is possible, m.in. to inform you about cookies operating on the App</td>
                  <td class="py-4 px-6">most of the necessary cookies are session cookies, but some remain on your device for a period of 1 year or until they are deleted</td>
                </tr>
                <tr>
                  <td class="py-4 px-6">Google Analytics</td>
                  <td class="py-4 px-6">Google</td>
                  <td class="py-4 px-6">This tool allows you to collect statistical data on how users use the App, m.in. the number of visits, the duration of visits, the search engine used, location. The collected data helps to improve the App and make it more friendly to customers.</td>
                  <td class="py-4 px-6">up to 2 years or until they are removed (whichever occurs first)</td>
                </tr>
                </tbody>
              </table>
            </div>

            <ol start="7" class="list-decimal pl-8 mt-6 space-y-2 text-slate-300">
              <li>Through most used browsers, you can check whether cookies have been installed on your end device, as well as delete installed cookies and block them from being installed by the App in the future. Disabling or limiting the use of cookies may, however, cause quite serious difficulties in using the App, e.g. in the form of the need to log in to each subpage, a longer loading period of the App's restrictions on the use of certain functionalities.</li>
            </ol>
          </section>

          <section class="mb-8">
            <h2 class="text-2xl font-display font-semibold text-slate-100 mb-4">
              Final provisions
            </h2>
            <p class="text-slate-300">
              To the extent not regulated by the Policy, generally applicable provisions on the protection of personal data shall apply.
            </p>
            <p class="text-slate-300 mt-4">
              This policy is effective from 18.12.2024
            </p>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useMetaTags } from "@/components/useMetaTags"

const route = useRoute()

const purposes = [
  {
    title: "Conclusion and performance of the Agreement for the provision of the Account Service",
    data: [
      "e-mail address",
      "password",
      "name and surname (in case of logging in via Google)",
      "profile photo (In case of logging in via Google)"
    ],
    legalBasis: "Article 6(1)(b) of the GDPR (processing is necessary for the performance of the Account Service Agreement concluded with the data subject or to take steps to conclude it)",
    provision: "Providing the above-mentioned personal data is a condition for the conclusion and performance of the contract for the provision of the Account Service (their provision is voluntary, but the consequence of not providing them will be the inability to conclude and perform the above-mentioned contract, including the creation of an Account).",
    period: "The Controller will process the above-mentioned personal data until the claims arising from the Agreement for the provision of the Account Service expire."
  },
  {
    title: "Conclusion and performance of the Agreement for the Use of the App",
    data: [
      "name and surname",
      "e-mail address",
      "billing address",
      "IP address"
    ],
    legalBasis: "Article 6(1)(b) of the GDPR (processing is necessary for the performance of the Agreement for the use of the App concluded with the data subject or to take steps to conclude it)",
    provision: "Providing the above-mentioned personal data is a condition for the conclusion and performance of the Agreement for the use of the App (their provision is voluntary, but the consequence of not providing them will be the inability to conclude and perform the Agreement for the use of the App).",
    period: "The Controller will process the above-mentioned personal data until the claims arising from the Agreement for the use of the App expire."
  },
  {
    title: "Conclusion and performance of the Newsletter Agreement",
    data: [
      "e-mail address"
    ],
    legalBasis: "Article 6(1)(b) of the GDPR (processing is necessary for the performance of the Newsletter Subscription Agreement/Digital Goods Provision Agreement concluded with the data subject or to take action to conclude it) and Article 6(1)(f) of the GDPR (processing is necessary to implement the legitimate interest of the Controller, in this case informing about new services and promotions available in the App)",
    provision: "Providing the above-mentioned personal data is voluntary, but necessary to receive the Newsletter (the consequence of not providing them will be the inability to receive the Newsletter).",
    period: "The Controller will process the above-mentioned personal data, the time of effective objection or achievement of the purpose of processing or until the claims arising from the Newsletter Subscription Agreement are time-barred (whichever occurs first)."
  },
  {
    title: "Conducting a complaint procedure",
    data: [
      "name and surname",
      "e-mail address"
    ],
    legalBasis: "Article 6(1)(c) of the GDPR (processing is necessary to fulfill a legal obligation incumbent on the Controller, in this case the obligations of responding to a complaint -- Article 7a of the Act on consumer rights and exercise the Client's rights resulting from the provisions on the Controller's liability in the event of non-compliance of the Subject of Digital Services with the Agreement concerning it)",
    provision: "Providing the above-mentioned personal data is a condition for receiving a response to the complaint or exercising the Client's rights under the provisions on the Controller's liability in the event of non-compliance of the Subject of Digital Services with the Agreement concerning it (their provision is voluntary, but the consequence of not providing them will be the inability to properly implement the above-mentioned rights).",
    period: "The Controller will process the above-mentioned personal data for the duration of the complaint procedure."
  },
  {
    title: "Conducting verification proceedings and dealing with appeals against decisions on the handling of unacceptable content",
    data: [
      "name and surname",
      "contact details, including email address"
    ],
    legalBasis: "Article 6(1)(c) of the GDPR (processing is necessary to fulfill a legal obligation incumbent on the Controller, in this case the obligations of providing a mechanism for reporting unacceptable content and consideration of complaints)",
    provision: "Providing the above-mentioned personal data is a condition for receiving a response to the notification and exercising the above-mentioned rights of the User.",
    period: "The Controller will process the above-mentioned personal data for the duration of the complaint procedure, and in the case of exercising the above-mentioned rights of the User -- until they expire."
  },
  {
    title: "Compliance with tax obligations",
    data: [
      "name and surname",
      "billing address",
      "IP address"
    ],
    legalBasis: "Article 6(1)(c) of the GDPR (processing is necessary to fulfill the legal obligation incumbent on the Controller, in this case obligations arising from tax law)",
    provision: "Providing the above-mentioned personal data is voluntary, but necessary for the Controller to meet its tax obligations (the consequence of not providing them will be the Controller's inability to meet the above-mentioned obligations).",
    period: "The Controller will process the above-mentioned personal data for a period of 5 years from the end of the year in which the tax payment deadline for the previous year expired."
  },
  {
    title: "Analysis of your activity in the App",
    data: [
      "date and time of visit",
      "IP number of the device",
      "device operating system type",
      "approximate location",
      "type of web browser",
      "time spent in the App",
      "visited subpages and other activities undertaken as part of the App"
    ],
    legalBasis: "Article 6(1)(f) of the GDPR (processing is necessary to implement the legitimate interest of the Controller, in this case obtaining information about your activity in the App)",
    provision: "Providing the above-mentioned personal data is voluntary, but necessary for the Controller to obtain information about your activity in the App (the consequence of not providing them will be the Controller's inability to obtain the above-mentioned information).",
    period: "The Controller will process the above-mentioned personal data until the effective objection is raised or the purpose of processing is achieved."
  },
  // Add these to the purposes array
  {
    title: "Sending email notifications",
    data: [
      "e-mail address"
    ],
    legalBasis: "Article 6(1)(f) of the GDPR (processing is necessary in order to implement the legitimate interest of the Controller, in this case informing Clients about the actions taken related to the performance of agreements concluded with Clients)",
    provision: "Providing the above-mentioned personal data voluntary, but necessary to receive information about activities related to the performance of agreements concluded with Clients (the consequence of not providing them will be the inability to receive the above-mentioned information).",
    period: "The Controller will process the above-mentioned personal data until the objection is effectively raised or the purpose of processing is achieved (whichever occurs first)."
  },
  {
    title: "Handling inquiries submitted by Clients",
    data: [
      "name",
      "e-mail address",
      "other data contained in the message to the Controller"
    ],
    legalBasis: "Article 6(1)(f) of the GDPR (processing is necessary to implement the legitimate interest of the Controller, in this case to respond to the received inquiry)",
    provision: "Providing the above-mentioned personal data is voluntary, but necessary to receive an answer to the inquiry (the consequence of not providing them will be the inability to receive an answer).",
    period: "The Controller will process the above-mentioned personal data until the objection is effectively raised or the purpose of processing is achieved (whichever occurs first)."
  },
  {
    title: "Fulfillment of obligations related to the protection of personal data",
    data: [
      "name and surname",
      "contact details provided by you (e-mail address; correspondence address; telephone number)"
    ],
    legalBasis: "Article 6(1)(c) of the GDPR (processing is necessary to fulfill the legal obligation incumbent on the Controller, in this case the obligations arising from the provisions on the protection of personal data)",
    provision: "Providing the above-mentioned personal data is voluntary, but necessary for the Controller to properly perform the obligations arising from the provisions on the protection of personal data, m.in. exercise the rights granted to you by the GDPR (the consequence of not providing the above-mentioned data will be the inability to properly implement the above-mentioned rights).",
    period: "The Controller will process the above-mentioned personal data until the expiry of the limitation periods for claims for violation of the provisions on the protection of personal data."
  },
  {
    title: "Establishing, investigating, or defending against claims",
    data: [
      "Name and surname/company",
      "e-mail address",
      "address of residence",
      "national identification number"
    ],
    legalBasis: "Article 6(1)(f) of the GDPR (processing is necessary to implement the legitimate interest of the Controller, in this case to establish, investigate or defend against claims that may arise in connection with the performance of Contracts concluded with the Controller)",
    provision: "Providing the above-mentioned personal data is voluntary, but necessary to establish, investigate or defend against claims that may arise in connection with the performance of the agreements concluded with the Controller (the consequence of not providing the above-mentioned data will be the inability of the Controller to take the above-mentioned actions)",
    period: "The Controller will process the above-mentioned personal data until the expiry of the limitation periods for claims that may arise in connection with the performance of the agreements concluded with the Controller."
  }
]

onMounted(() => {
  useMetaTags({
    title: 'Privacy Policy',
    path: route.path,
  })
})
</script>