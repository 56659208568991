<template>
  <nav class="fixed top-0 left-0 right-0 z-50 bg-slate-800/90 backdrop-blur-sm border-b border-slate-700/50">
    <!-- Desktop Layout -->
    <div class="hidden md:block">
      <div class="max-w-6xl mx-auto px-6 py-3">
        <div class="flex items-center justify-between">
          <!-- Left: Logo -->
          <router-link
              to="/"
              class="text-2xl font-display font-bold text-gradient hover:scale-[1.02] transition-transform"
          >
            Kids Tell Tales
          </router-link>

          <!-- Right: Navigation -->
          <div v-if="isLoaded" class="flex items-center space-x-6">
            <!-- Show these links when logged in -->
            <template v-if="user">
              <router-link
                  to="/sessions/new"
                  class="text-slate-300 hover:text-white transition-colors duration-200 flex items-center space-x-2 group"
                  :class="{ 'text-white': $route.path === '/sessions/new' }"
              >
                <Sparkles class="h-4 w-4 group-hover:scale-110 transition-transform"/>
                <span>New Story</span>
              </router-link>

              <router-link
                  to="/history"
                  class="text-slate-300 hover:text-white transition-colors duration-200 flex items-center space-x-2"
                  :class="{ 'text-white': $route.path === '/history' }"
              >
                <BookOpen class="h-4 w-4"/>
                <span>Your Stories</span>
              </router-link>
            </template>

            <!-- Show pricing for non-logged in users -->
            <template v-else>
              <router-link
                  to="/purchase"
                  class="text-slate-300 hover:text-white transition-colors duration-200 flex items-center space-x-2"
                  :class="{ 'text-white': $route.path === '/purchase' }"
              >
                <Ticket class="h-4 w-4"/>
                <span>Pricing</span>
              </router-link>
            </template>

            <!-- Help is always visible -->
            <router-link
                to="/help"
                class="text-slate-300 hover:text-white transition-colors duration-200 flex items-center space-x-2"
                :class="{ 'text-white': $route.path === '/help' }"
            >
              <HelpCircle class="h-4 w-4"/>
              <span>Help</span>
            </router-link>

            <!-- Account or Sign In -->
            <template v-if="user">
              <router-link
                  to="/account"
                  class="text-slate-300 hover:text-white transition-colors duration-200 flex items-center space-x-2"
                  :class="{ 'text-white': $route.path === '/account' }"
              >
                <User2 class="h-4 w-4"/>
                <span>Account</span>
              </router-link>
            </template>
            <template v-else>
              <router-link
                  to="/login"
                  class="text-slate-300 hover:text-white transition-colors duration-200 flex items-center space-x-2 group"
              >
                <LogIn class="h-4 w-4 group-hover:translate-x-0.5 transition-transform"/>
                <span>Sign In</span>
              </router-link>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile Layout -->
    <div class="md:hidden">
      <div class="px-4 py-3">
        <div class="flex items-center justify-between">
          <!-- Logo -->
          <router-link
              to="/"
              class="text-xl font-display font-bold text-gradient"
          >
            Kids Tell Tales
          </router-link>

          <!-- Mobile Actions -->
          <div class="flex items-center space-x-3">
            <router-link
                v-if="user"
                to="/sessions/new"
                class="w-10 h-10 flex items-center justify-center rounded-full bg-blue-600/10 border border-blue-500/20 text-blue-400 group"
            >
              <Sparkles class="h-5 w-5 group-hover:scale-110 transition-transform" aria-label="New Story"/>
            </router-link>

            <router-link
                v-if="!user"
                to="/login"
                class="btn-primary flex items-center space-x-2 group"
            >
              <LogIn class="h-4 w-4 group-hover:translate-x-0.5 transition-transform"/>
              <span>Sign In</span>
            </router-link>

            <div v-if="isLoaded">
              <!-- Show menu button only when logged in -->
              <button
                  @click="isMenuOpen = !isMenuOpen"
                  class="w-10 h-10 flex items-center justify-center rounded-full bg-slate-800 border border-slate-700/50"
                  :aria-expanded="isMenuOpen"
                  aria-controls="mobile-menu"
              >
                <span class="sr-only">Open main menu</span>
                <Menu class="h-5 w-5 text-slate-300" :class="{ 'hidden': isMenuOpen }"/>
                <X class="h-5 w-5 text-slate-300" :class="{ 'hidden': !isMenuOpen }"/>
              </button>

              <!-- Show Get Started button when not logged in -->
            </div>
          </div>
        </div>
      </div>

      <!-- Mobile Menu -->
      <div
          id="mobile-menu"
          v-show="isMenuOpen"
          class="border-t border-slate-700/50"
      >
        <div class="px-4 py-3 space-y-3">
          <!-- Show pricing for non-logged in users -->
          <template v-if="!user">
            <router-link
                to="/purchase"
                class="flex items-center space-x-2 px-3 py-2 rounded-lg text-slate-300 hover:text-white hover:bg-slate-700/50 transition-all duration-200"
                :class="{ 'bg-slate-700/50 text-white': $route.path === '/purchase' }"
                @click="isMenuOpen = false"
            >
              <Ticket class="h-4 w-4"/>
              <span>Pricing</span>
            </router-link>
          </template>

          <router-link
              v-if="user"
              to="/history"
              class="flex items-center space-x-2 px-3 py-2 rounded-lg text-slate-300 hover:text-white hover:bg-slate-700/50 transition-all duration-200"
              :class="{ 'bg-slate-700/50 text-white': $route.path === '/history' }"
              @click="isMenuOpen = false"
          >
            <BookOpen class="h-4 w-4"/>
            <span>Your Stories</span>
          </router-link>
          <router-link
              to="/help"
              class="flex items-center space-x-2 px-3 py-2 rounded-lg text-slate-300 hover:text-white hover:bg-slate-700/50 transition-all duration-200"
              :class="{ 'bg-slate-700/50 text-white': $route.path === '/help' }"
              @click="isMenuOpen = false"
          >
            <HelpCircle class="h-4 w-4"/>
            <span>Help</span>
          </router-link>
          <router-link
              to="/account"
              v-if="user"
              class="flex items-center space-x-2 px-3 py-2 rounded-lg text-slate-300 hover:text-white hover:bg-slate-700/50 transition-all duration-200"
              :class="{ 'bg-slate-700/50 text-white': $route.path === '/account' }"
              @click="isMenuOpen = false"
          >
            <User2 class="h-4 w-4"/>
            <span>Account</span>
          </router-link>
        </div>
      </div>
    </div>
  </nav>

  <!-- Spacer to prevent content from being hidden under the fixed navbar -->
  <div class="h-[48px] md:h-[56px]"></div>
</template>

<script setup lang="ts">
import {onMounted, ref} from 'vue'
import {getCurrentUser, useCurrentUser} from 'vuefire'
import {
  BookOpen,
  LogIn,
  Menu,
  Sparkles,
  User2,
  X,
  HelpCircle,
  Ticket
} from 'lucide-vue-next'

const user = useCurrentUser()
const isMenuOpen = ref(false)
const isLoaded = ref(false)

onMounted(async () => {
  await getCurrentUser()
  isLoaded.value = true
})
</script>