// src/components/useMetaTags.ts
import { useRoute } from 'vue-router'

interface MetaOptions {
    title: string;
    path: string;
    description?: string;
}

const defaultDescription = 'Personalized AI adventures that spark your child\'s imagination'

export function useMetaTags(options: MetaOptions) {
    const baseUrl = 'https://kidstelltales.com' // Added https:// for complete URL
    const description = options.description || defaultDescription
    const fullTitle = options.title != ""? `${options.title} - Kids Tell Tales` : 'Kids Tell Tales'
    const canonicalUrl = `${baseUrl}${options.path}`

    // Update basic meta tags
    document.title = fullTitle
    let descTag = document.querySelector('meta[name="description"]')
    if (descTag) {
        descTag.setAttribute('content', description)
    }

    // Update canonical URL
    let canonicalTag = document.querySelector('link[rel="canonical"]')
    if (!canonicalTag) {
        canonicalTag = document.createElement('link')
        canonicalTag.setAttribute('rel', 'canonical')
        document.head.appendChild(canonicalTag)
    }
    canonicalTag.setAttribute('href', canonicalUrl)

    // Update Open Graph and Twitter tags
    const metaTags = {
        // Open Graph
        'og:title': fullTitle,
        'og:description': description,
        'og:url': canonicalUrl,
        'og:type': 'website',

        // Twitter
        'twitter:title': fullTitle,
        'twitter:description': description,
        'twitter:url': canonicalUrl,
        'twitter:card': 'summary_large_image'
    }

    Object.entries(metaTags).forEach(([name, content]) => {
        const tag = document.querySelector(`meta[property="${name}"]`)
        if (tag) {
            tag.setAttribute('content', content)
        }
    })

    // Update schema markup
    const schema = document.querySelector('script[type="application/ld+json"]')
    if (schema) {
        try {
            const data = JSON.parse(schema.textContent || '{}')
            data.name = fullTitle
            data.description = description
            data.url = canonicalUrl
            schema.textContent = JSON.stringify(data)
        } catch (e) {
            console.error('Error updating schema markup:', e)
        }
    }
}