<template>
  <div v-if="userLoaded" class=" p-6 space-y-8">
    <!-- Active Subscription State -->
    <div v-if="(subscriptionInfo.available && subscriptionInfo.hasActiveSubscription) || isAlreadyPaid" class="text-center space-y-6">
      <CircleCheck class="h-12 w-12 mx-auto text-emerald-400"/>
      <div>
        <h2 class="text-xl font-display font-bold text-slate-100 mb-2">
          You Already Have an Active Plan
        </h2>
        <p class="text-slate-300">
          Your plan is valid until {{ formatDate(subscriptionInfo.subscriptionEndDate) }} and you still can have
          {{ subscriptionInfo.interactionsLeft }} AI interactions before reaching the limit for the period.
        </p>
      </div>
      <button
          @click="redirectToHistory"
          class="w-full px-6 py-4 bg-blue-600 hover:bg-blue-500
              text-white rounded-lg text-lg font-semibold
              transition-all duration-200
              flex items-center justify-center space-x-3
              group"
      >
        <BookOpen class="h-5 w-5 group-hover:scale-110 transition-transform"/>
        <span>Return to Stories</span>
      </button>
    </div>

    <template v-else>
      <div class="text-center mb-8">
        <h1 class="text-3xl md:text-4xl font-display font-bold text-slate-100 mb-4">We Have Plans for Everyone</h1>
        <p class="text-slate-400 max-w-2xl mx-auto">Whether
          you're just starting out or ready for more adventures, we have options for everyone.</p>
      </div>
      <div class="grid md:grid-cols-2 gap-6 max-w-5xl mx-auto">
        <!-- Free Plan -->
        <div
            class="relative overflow-hidden rounded-xl bg-gradient-to-r from-slate-800/50 via-slate-700/50 to-slate-800/50 p-6 border border-slate-700/50">
          <div class="relative flex justify-between items-start">
            <div class="space-y-2">
              <h2 class="text-2xl font-display font-bold text-slate-100">Free Trial</h2>
              <div class="text-lg text-slate-300 flex items-center space-x-2">
                <span>First 21 AI interactions</span>
              </div>
            </div>
            <div class="text-right">
              <div class="text-3xl font-display font-bold text-slate-100">$0</div>
            </div>
          </div>

          <div class="mt-8 text-red-400" v-if="sessionStore.currentUser?.active_period.not_eligible_for_free_tier">
            {{sessionStore.currentUser?.active_period.not_eligible_for_free_tier}}
          </div>
          <div class="mt-8 text-red-400" v-else-if="subscriptionInfo.available && !subscriptionInfo.hasActiveSubscription">
            You're already used up all interactions in your free tier
          </div>
          <div v-else>
            <div class="mt-8">
              <div class="text-center space-y-2">
                <p class="text-sm text-slate-300 text-left">
                  Choose how to use your interactions:
                </p>
                <div class="grid grid-cols-3 gap-4 pt-2">
                  <div class="text-center space-y-1">
                    <div class="text-2xl">🐁</div>
                    <div class="text-slate-200 font-medium">4</div>
                    <div class="text-sm text-slate-400">short stories</div>
                  </div>

                  <div class="text-center space-y-1">
                    <div class="text-2xl">🐈</div>
                    <div class="text-slate-200 font-medium">3</div>
                    <div class="text-sm text-slate-400">medium stories</div>
                  </div>

                  <div class="text-center space-y-1">
                    <div class="text-2xl">🐘</div>
                    <div class="text-slate-200 font-medium">2</div>
                    <div class="text-sm text-slate-400">long stories</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-6">
              <div v-for="(benefit, index) in benefits"
                   :key="index"
                   class="flex items-start space-x-3 p-2 rounded-lg">
                <CheckCircle class="h-5 w-5 text-emerald-400 flex-shrink-0 mt-0.5"/>
                <span class="text-slate-300">{{ benefit }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Premium Plan -->
        <div
            class="relative overflow-hidden rounded-xl bg-gradient-to-r from-blue-600/10 via-indigo-600/10 to-blue-600/10 p-6">
          <div class="absolute inset-0 bg-blue-500/5"></div>
          <div class="relative flex justify-between items-start">
            <div class="space-y-2">
              <h2 class="text-2xl font-display font-bold bg-gradient-to-r from-blue-400 to-indigo-400 bg-clip-text text-transparent">
                30-Day Plan
              </h2>
              <div class="text-lg text-slate-300 flex items-center space-x-2">
                <span>450 AI interactions</span>
              </div>
            </div>
            <div class="text-right">
              <div class="text-3xl font-display font-bold text-slate-100">$7.99</div>
              <div class="text-sm text-slate-400">+ tax</div>
            </div>
          </div>

          <div class="mt-8">
            <div class="text-center space-y-2">
              <p class="text-sm text-slate-300 text-left">
                Choose how to use your interactions:
              </p>
              <div class="grid grid-cols-3 gap-4 pt-2">
                <div class="text-center space-y-1">
                  <div class="text-2xl">🐁</div>
                  <div class="text-slate-200 font-medium">90</div>
                  <div class="text-sm text-slate-400">short stories</div>
                </div>

                <div class="text-center space-y-1">
                  <div class="text-2xl">🐈</div>
                  <div class="text-slate-200 font-medium">64</div>
                  <div class="text-sm text-slate-400">medium stories</div>
                </div>

                <div class="text-center space-y-1">
                  <div class="text-2xl">🐘</div>
                  <div class="text-slate-200 font-medium">50</div>
                  <div class="text-sm text-slate-400">long stories</div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-6">
            <div v-for="(benefit, index) in benefits"
                 :key="index"
                 class="flex items-start space-x-3 p-2 rounded-lg">
              <CheckCircle class="h-5 w-5 text-emerald-400 flex-shrink-0 mt-0.5"/>
              <span class="text-slate-300">{{ benefit }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- More subtle interactions breakdown -->
      <div class="space-y-4">


        <div v-if="user" class="space-y-4 text-sm text-slate-300">
          <!-- Terms and Conditions Checkbox -->
          <label class="flex items-start space-x-3 cursor-pointer group">
            <div class="relative flex items-start">
              <div class="bg-slate-800/50 border border-slate-600/50 rounded w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 mt-0.5
                  group-hover:border-blue-500/50 transition-colors">
                <input
                    type="checkbox"
                    v-model="acceptTerms"
                    class="opacity-0 absolute cursor-pointer"
                >
                <svg
                    class="fill-current w-3 h-3 text-blue-400 pointer-events-none"
                    viewBox="0 0 20 20"
                    :class="{ 'hidden': !acceptTerms }"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                </svg>
              </div>
            </div>
            <span class="select-none">
      I have read and agree to the
      <a href="/terms" target="_blank" class="text-blue-400 hover:text-blue-300">Terms and Conditions</a> and
      <a href="/privacy" target="_blank" class="text-blue-400 hover:text-blue-300">Privacy Policy</a>.
    </span>
          </label>

          <!-- Withdrawal Consent Checkbox -->
          <label class="flex items-start space-x-3 cursor-pointer group">
            <div class="relative flex items-start">
              <div class="bg-slate-800/50 border border-slate-600/50 rounded w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 mt-0.5
                  group-hover:border-blue-500/50 transition-colors">
                <input
                    type="checkbox"
                    v-model="acceptWithdrawal"
                    class="opacity-0 absolute cursor-pointer"
                >
                <svg
                    class="fill-current w-3 h-3 text-blue-400 pointer-events-none"
                    viewBox="0 0 20 20"
                    :class="{ 'hidden': !acceptWithdrawal }"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                </svg>
              </div>
            </div>
            <span class="select-none">
      I agree to start using the service immediately and understand that by doing so, I will no longer be able to cancel my purchase and get a full refund.
    </span>
          </label>
        </div>

        <button
            @click="handleAction"
            :disabled="sessionStore.loading || !canProceed"
            class="w-full px-6 py-4 bg-blue-600 hover:bg-blue-500
        disabled:bg-blue-600/50 disabled:cursor-not-allowed
        text-white rounded-lg text-lg font-semibold
        transition-all duration-200
        flex items-center justify-center space-x-3
        group"
        >
          <span v-if="sessionStore.loading" class="flex items-center space-x-2">
            <span>Processing</span>
            <LoadingDots/>
          </span>
          <span v-else class="flex items-center space-x-2">
            <component
                :is="user ? CreditCard : LogIn"
                class="h-5 w-5 group-hover:scale-110 transition-transform"
            />
            <span>{{ user ? 'Purchase 30-day plan with Stripe' : 'Sign in for your free trial' }}</span>
          </span>
        </button>

      </div>

      <!-- Error Message -->
      <ErrorBox/>
    </template>
  </div>
</template>

<script setup lang="ts">
import {computed, onMounted, ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {useSessionStore} from '@/store/session'
import {BookOpen, CheckCircle, CircleCheck, CreditCard, LogIn} from 'lucide-vue-next'
import LoadingDots from './LoadingDots.vue'
import ErrorBox from "@/components/ErrorBox.vue"
import {useSubscription} from '@/composables/useSubscription'
import {useMetaTags} from "@/components/useMetaTags"
import {getCurrentUser, useCurrentUser} from 'vuefire'

const router = useRouter()
const route = useRoute()
const sessionStore = useSessionStore()
const {subscriptionInfo, formatDate} = useSubscription()
const isAlreadyPaid = ref(false)
const user = useCurrentUser()
const userLoaded = ref(false)
const showDetails = ref(false)
const acceptTerms = ref(false)
const acceptWithdrawal = ref(false)

const benefits = [
  'Create stories about any topic you love',
  'Let your child choose how each story unfolds',
  'Pick up right where you left off with saved stories',
  'Share magical adventures with friends',
  'Keep all your stories forever, even after your plan ends'
]

// Add this with other computed properties
const canProceed = computed(() => {
  if (!user.value) return true // Don't block the "Sign In" button
  return acceptTerms.value && acceptWithdrawal.value
})

const handleAction = async () => {
  if (!user.value) {
    router.push('/login')
    return
  }

  if (!sessionStore.currentUser) {
    await sessionStore.fetchUser()
  }

  if (subscriptionInfo.value.hasActiveSubscription) {
    return
  }

  const response = await sessionStore.createPayment(acceptTerms.value, acceptWithdrawal.value)

  if (response?.is_paid_already) {
    isAlreadyPaid.value = true
  } else if (response?.session_url) {
    window.location.href = response.session_url
  }
}

const redirectToHistory = () => {
  router.push('/history')
}

onMounted(async () => {
  useMetaTags({
    title: 'Purchase',
    path: route.path,
  })

  await getCurrentUser()

  if (user.value && !sessionStore.currentUser) {
    await sessionStore.fetchUser()
  }
  userLoaded.value = true
})
</script>