import {createRouter, createWebHistory} from 'vue-router'
import {getCurrentUser} from 'vuefire'
import {useSessionStore} from '@/store/session'
import Main from "@/components/Main.vue";
import History from "@/components/History.vue";
import Login from "@/components/Login.vue";
import Account from "@/components/Account.vue";
import NewSession from "@/components/NewSession.vue";
import ExistingSession from "@/components/ExistingSession.vue";
import Purchase from "@/components/Purchase.vue";
import VerifyEmail from "@/components/VerifyEmail.vue";
import FinishAuth from "@/components/FinishAuth.vue";
import ResetPassword from "@/components/ResetPassword.vue";
import PurchaseSuccess from "@/components/PurchaseSuccess.vue";
import Help from "@/components/Help.vue";
import GeoRestriction from "@/components/GeoRestriction.vue";
import Dmca from "@/components/Dmca.vue";
import TermsAndConditions from "@/components/TermsAndConditions.vue";
import Privacy from "@/components/Privacy.vue";
import NewsletterTerms from "@/components/NewsletterTerms.vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Main,
    },
    {
        path: '/account',
        name: 'Account',
        component: Account,
        meta: {requiresAuth: true}
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/restricted',
        name: 'Restricted',
        component: GeoRestriction,
    },
    {
        path: '/history',
        name: 'History',
        component: History,
        meta: {requiresAuth: true}
    },
    {
        path: '/sessions/new',
        name: 'NewSession',
        component: NewSession,
        meta: {requiresAuth: true}
    },
    {
        path: '/sessions/:sessionId',
        name: 'Session',
        component: ExistingSession,
        props: true,
    },
    {
        path: '/purchase',
        name: 'Purchase',
        component: Purchase,
    },
    {
        path: '/verify-email',
        name: 'VerifyEmail',
        component: VerifyEmail,
        meta: {requiresAuth: true}
    },
    {
        path: '/finish-auth',
        name: 'FinishAuth',
        component: FinishAuth,
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
    },
    {
        path: '/purchase/success',
        name: 'PurchaseSuccess',
        component: PurchaseSuccess,
        meta: {requiresAuth: true}
    },
    {
        path: '/help',
        name: 'Help',
        component: Help,
    },
    {
        path: '/dmca',
        name: 'DMCA',
        component: Dmca,
    },
    {
        path: '/terms',
        name: 'TermsAndConditions',
        component: TermsAndConditions,
    },
    {
        path: '/privacy',
        name: 'PrivacyPolicy',
        component: Privacy,
    },
    {
        path: '/newsletter-terms',
        name: 'NewsletterTerms',
        component: NewsletterTerms,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // Always scroll to top for new navigation
        return { top: 0 }
    }
})

// Clear store error and loading states before each navigation
router.beforeEach(async (to) => {
    const sessionStore = useSessionStore()
    sessionStore.error = null
    sessionStore.loading = false
    sessionStore.loadingNewAnswer = false

    // Handle home page redirect for logged in users
    if (to.path === "/") {
        const currentUser = await getCurrentUser()
        if (currentUser) {
            return {path: '/history'}
        }
    }

    // Handle login page redirect for logged in users
    if (to.path === "/login") {
        const currentUser = await getCurrentUser()
        if (currentUser) {
            return {path: '/history'}
        }
    }

    // Handle authentication and email verification
    if (to.meta.requiresAuth) {
        const currentUser = await getCurrentUser()

        if (!currentUser) {
            return {
                path: '/login',
            }
        }

        if(to.path !== "/verify-email" && !currentUser.emailVerified) {
            return { path: '/verify-email' }
        }
        if(to.path === "/verify-email" && currentUser.emailVerified) {
            return {path: '/history'}
        }
    }
})

export default router