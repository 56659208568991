// src/composables/useEmailValidation.ts
import { ref } from 'vue'

interface ValidationResult {
    isValid: boolean
    error: string | null
}

export function useEmailValidation() {
    const disallowedDomains = ref<string[]>([])

    const loadDisallowedDomains = async () => {
        if (disallowedDomains.value.length > 0) return

        try {
            const response = await fetch('/disallowed-domains.txt')
            const text = await response.text()
            disallowedDomains.value = text.split('\n').map(domain => domain.trim()).filter(domain => domain)
        } catch (error) {
            console.error('Failed to load disallowed domains:', error)
        }
    }

    const validateEmailFormat = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(email)
    }

    const validateEmail = async (email: string, checkDomain = false): Promise<ValidationResult> => {
        if (!validateEmailFormat(email)) {
            return { isValid: false, error: 'Please enter a valid email address' }
        }

        if (!checkDomain) {
            return { isValid: true, error: null }
        }

        await loadDisallowedDomains()
        const domain = email.split('@')[1].toLowerCase()
        if (disallowedDomains.value.includes(domain)) {
            return { isValid: false, error: "We are really sorry, but can't accept email from this domain"}
        }

        return { isValid: true, error: null }
    }

    return {
        validateEmail,
    }
}